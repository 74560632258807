import React, {useEffect, useState} from 'react';
import {IconButton, Typography, Box, FormControl, FormControlLabel, Checkbox} from '@mui/material';
import useTranslations from '../../../hooks/useTranslation';
import Modal from '../../../components/common/Modal';
import {updateOrganization} from '../../../api';
import useAuthStore from '../../../store/authStore';
import IconContainer from '../../../components/common/IconContainer';
import {editsIcon} from '../../../utils/svg';

const GroupLevel = ({data, reloadData}) => {
  const {translate} = useTranslations();
  const {selectedOrganization} = useAuthStore(state => state);
  const [isEditing, setIsEditing] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [groupLevel, setGroupLevel] = useState({
    D1: false,
    D2: false,
    D3: false,
    NA: false
  });

  const handlePromoteChange = name => {
    let updatedGroupLevel = {...groupLevel};
    if (name === 'NA') {
      updatedGroupLevel = {D1: false, D2: false, D3: false, NA: true};
    } else if (name === 'D1') {
      updatedGroupLevel = {D1: true, D2: false, D3: false, NA: false};
    } else if (name === 'D2') {
      updatedGroupLevel = {D1: true, D2: !groupLevel.D2, D3: false, NA: false};
    } else if (name === 'D3') {
      updatedGroupLevel = {D1: true, D2: true, D3: !groupLevel.D3, NA: false};
    }

    setGroupLevel(updatedGroupLevel);
  };

  const handleSubmitClick = async () => {
    setButtonLoader(true);
    try {
      const default_approved_level = groupLevel.NA
        ? "NA"
        : Object.keys(groupLevel)
            .filter(key => groupLevel[key] && key !== 'NA')
            .join(',');
      const res = await updateOrganization(selectedOrganization.id, {default_approved_level}, 'group_level');
      if (res?.data?.status === 200) {
        setIsEditing(false);
        reloadData();
      }
      setButtonLoader(false);
    } catch (error) {
      setButtonLoader(false);
    }
  };

  const resetData = () => {
    if (data?.default_approved_level) {
      const levels = data.default_approved_level.split(',');
      setGroupLevel({
        D1: levels.includes('D1'),
        D2: levels.includes('D2'),
        D3: levels.includes('D3'),
        NA: false
      });
    } else {
      setGroupLevel({
        D1: false,
        D2: false,
        D3: false,
        NA: true
      });
    }
  };
  useEffect(() => {
    resetData();
  }, [data]);

  return (
    <>
      <Box
        sx={{
          width: '50%',
          padding: '16px',
          borderRadius: '8px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#ffffff',
          height: '80px'
        }}
      >
        <Typography
          variant="h6"
          mb={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: '#000'
          }}
        >
          {translate('Group Level')}
          <IconButton onClick={() => setIsEditing(true)} size="small" aria-label="edit">
            <IconContainer icon={editsIcon} width={20} height={20} />
          </IconButton>
        </Typography>
        <Box sx={{marginTop: '8px'}}>
          <Typography
            variant="h5"
            mb={1}
            sx={{
              textAlign: 'start',
              color: '#000',
              fontWeight: 'bold'
            }}
          >
            {data?.default_approved_level || 'NA'}
          </Typography>
        </Box>
      </Box>

      <Modal
        open={isEditing}
        onClose={() => {
          setIsEditing(false);
          resetData();
        }}
        title={translate('Edit Group Level')}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        buttons={[
          {
            name: translate('Cancel'),
            onClick: () => {
              setIsEditing(false);
              resetData();
            },
            buttonBgColor: '#ffffff',
            buttonTextColor: 'black',
            buttonHoverColor: '#f5f5f5',
            type: 'outlined'
          },
          {
            name: translate('Save'),
            onClick: handleSubmitClick,
            buttonBgColor: '#d32f2f',
            buttonTextColor: '#ffffff',
            buttonHoverColor: '#b71c1c',
            loading: buttonLoader,
            disabled: buttonLoader
          }
        ]}
      >
        <FormControl fullWidth sx={{marginTop: '12px', display: 'flex', flexDirection: 'column', gap: '4px'}}>
          <Box sx={{display: 'flex', gap: '4px'}}>
            {['D1', 'D2', 'D3', 'NA'].map(level => (
              <FormControlLabel
                key={level}
                sx={{'& .MuiFormControlLabel-label': {color: '#000000'}, mb: 0}}
                control={
                  <Checkbox
                    checked={groupLevel[level]}
                    onChange={() => handlePromoteChange(level)}
                    sx={{
                      color: '#000000',
                      '&.Mui-checked': {
                        color: '#000000'
                      },
                      py: 0.2
                    }}
                  />
                }
                label={translate(level)}
              />
            ))}
          </Box>
        </FormControl>
      </Modal>
    </>
  );
};

export default GroupLevel;
