import React from 'react';
import useTranslations from '../hooks/useTranslation';
import useAuthStore from '../store/authStore';
import {updateUserPreferedLangApi} from '../api';
import { CONSTANTS } from '../constants';


const LanguageSwitcher = () => {
  const {switchLanguage} = useTranslations();
  const state = JSON.parse(localStorage.getItem('auth-storage'));
  const {selectedOrganization, updateOrgDefaultLanguage,updateUserPreferedLang,user} = useAuthStore(state => state);
  const preferredLanguage = state?.state?.user?.user_prefered_lang;
  const language = preferredLanguage ? CONSTANTS.LANG[preferredLanguage].key : CONSTANTS.LANG[selectedOrganization?.default_language];
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '8px',
    width: '100%',
    padding: '4px 0',
    marginBottom: "10px"
  };

  const buttonStyle = {
    borderRadius: '8px',
    border: '1px solid transparent',
    padding: '0.5em 0.8em',
    fontSize: '0.8em',
    fontWeight: 500,
    fontFamily: 'inherit',
    color: '#ccc',
    backgroundColor: '#000000',
    cursor: 'pointer',
    transition: 'border-color 0.25s, background-color 0.25s'
  };

  const selectedButtonStyle = {
    ...buttonStyle,
    color: '#fff',
    backgroundColor: '#9e0001',
    borderColor: '#fd4958'
  };
  const handleLanguageChange = async (lang = 1) => {
    try {
      const response = await updateUserPreferedLangApi(user?.id, lang);
      if (response?.data?.status === 200) {  
        updateUserPreferedLang(lang); // Update Zustand state
        // switchLanguage(lang); // Apply UI language change
        // updateOrgDefaultLanguage(lang)
      }
    } catch (error) {}
  };  
  return (
    <div style={containerStyle}>
      <button
        type="button"
        onClick={() => {
          // switchLanguage(1);
          // updateOrgDefaultLanguage({default_language: 1});
          handleLanguageChange(1)

        }}
        style={language == 1 ? selectedButtonStyle : buttonStyle}
      >
        English
      </button>
      <button
        type="button"
        onClick={() => {
          // switchLanguage(2);
          // updateOrgDefaultLanguage({default_language: 2});
          handleLanguageChange(2);

        }}
        style={language == 2 ? selectedButtonStyle : buttonStyle}
      >
        {/* Español */}
        Spanish
      </button>
      <button
        type="button"
        onClick={() => {
          // switchLanguage(1);
          // updateOrgDefaultLanguage({default_language: 1});
          handleLanguageChange(3)

        }}
        style={language == 3 ? selectedButtonStyle : buttonStyle}
      >
        Swedish
      </button>
      <button
        type="button"
        onClick={() => {
          // switchLanguage(1);
          // updateOrgDefaultLanguage({default_language: 1});
          handleLanguageChange(4)

        }}
        style={language == 4 ? selectedButtonStyle : buttonStyle}
      >
        English(UK)
      </button>
      {/* change later */}
      {/* <button
        type="button"
        onClick={() => {
          switchLanguage(3);
          updateOrgDefaultLanguage({default_language: 3});
        }}
        style={language == 3 ? selectedButtonStyle : buttonStyle}
      >
        हिंदी
        Urdu
      </button> */}
    </div>
  );
};

export default LanguageSwitcher;

