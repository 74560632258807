import React, {useState, useEffect} from 'react';
import TextInput from '../components/common/TextInput';
import {Typography} from '@mui/material';
import useTranslations from '../hooks/useTranslation';
import SelectInput from '../components/common/SelectInput';
import {Box} from '@mui/system';
import DatePicker from '../components/common/DatePicker';
import moment from 'moment';
import TimePickerComponent from '../components/common/TimePicker';

const ChangeMeetingDay = ({initialData, onDataChange}) => {
  const {translate} = useTranslations();
  const [formData, setFormData] = useState({
    location: '',
    date: null,
    time: null,
    day: ''
  });
  const [isPastDateTime, setIsPastDateTime] = useState(false);

  const [dateOpen, setDateOpen] = useState(false);
  // Initialize form data from initial data
  useEffect(() => {
    if (initialData) {
      setFormData({
        location: initialData.meeting_place || '',
        date: initialData.meeting_date ? moment(initialData.meeting_date) : null,
        time: initialData.meeting_time ? moment.utc(initialData.meeting_time).local() : null,
        day: initialData.meeting_day || ''
      });
    }
  }, [initialData]);

  // Sync form data with parent component
  useEffect(() => {
    onDataChange('location', formData.location);
    onDataChange('date', formData.date);
    onDataChange('time', formData.time);
    onDataChange('day', formData.day);
  }, [formData]);

  const weekDays = [
    {label: translate('Monday'), value: 'monday'},
    {label: translate('Tuesday'), value: 'tuesday'},
    {label: translate('Wednesday'), value: 'wednesday'},
    {label: translate('Thursday'), value: 'thursday'},
    {label: translate('Friday'), value: 'friday'},
    {label: translate('Saturday'), value: 'saturday'},
    {label: translate('Sunday'), value: 'sunday'}
  ];

  const handleInputChange = (field, value) => {
    setFormData(prevState => {
      let updatedData = {...prevState, [field]: value};

      if (field === 'day') {
        const nextMeetingDate = getNextMeetingDate(value);
        updatedData.date = nextMeetingDate;
      }

      return updatedData;
    });
  };

  const getNextMeetingDate = selectedDay => {
    const oldDayIndex = moment().day(translate(initialData.meeting_day)).day();
    let newDayIndex = moment().day(translate(selectedDay)).day();
    if (newDayIndex === 0) {
      newDayIndex = 7;
    }

    let difference = newDayIndex - oldDayIndex;
    if (difference > 0 && difference <= 4) {
      // difference = difference + 7;
    } else if (difference < 0) {
      difference = 7 - difference * -1;
    }
    let nextMeetingDate = moment(initialData.meeting_date || moment())
      .startOf('day')
      .add(difference, 'day');

    return nextMeetingDate;
  };

  const shouldDisableDate = date => {
    let enabledDate = formData.date ? moment(formData.date).format('YYYY-MM-DD') : initialData?.meeting_date ? moment(initialData.meeting_date).format('YYYY-MM-DD') : null;

    return enabledDate ? moment(date).format('YYYY-MM-DD') !== enabledDate : true;
  };

  useEffect(() => {
    if (formData.date && formData.time) {
      const selectedDateTime = moment(formData.date).set({hour: formData.time.hour(), minute: formData.time.minute()});
      setIsPastDateTime(selectedDateTime.isBefore(moment()));
    } else {
      setIsPastDateTime(false);
    }
  }, [formData.date, formData.time]);

  return (
    <>
      <hr style={{border: '0.5px solid #FFFFFF', width: '100%', margin: '2px auto'}} />
      <Typography fontSize={'30px'} mb={1} mt={1} fontWeight={500}>
        {translate('Change Meeting Day')}
      </Typography>
      <hr style={{border: '0.5px solid #FFFFFF', width: '100%', margin: '2px auto'}} />

      <Typography textAlign={'left'}>{translate('Select which date, one of your current meeting dates, in the future that you would like to make the change. We will cancel the meeting you pick and begin the new meeting day that same week. Then select your new meeting day of week and time. The app will recalculate all reading and update the disciples app.')}</Typography>

      <Box sx={{width: '75%'}}>
        <SelectInput disabled={isPastDateTime} label={translate('What Day Will You Meet')} value={formData.day} setValue={value => handleInputChange('day', value)} optionArray={weekDays} />
      </Box>

      <Box>
        <Typography mb={0.8} textAlign="left">
          {translate('DATE TO MAKE THE CHANGE')}
        </Typography>
        <DatePicker disabled={!formData.date || isPastDateTime} open={dateOpen} setOpen={() => setDateOpen(true)} onClose={() => setDateOpen(false)} value={formData.date} onChange={newValue => handleInputChange('date', newValue)} minDate={moment().subtract(1, 'year')} showDaysOutsideCurrentMonth shouldDisableDate={shouldDisableDate} />
      </Box>

      <Box sx={{width: '78%'}}>
        <TimePickerComponent disabled={isPastDateTime} label={translate('WHAT TIME WILL YOU MEET?')} value={formData.time} onChange={newValue => handleInputChange('time', newValue)} />
      </Box>

      <Box mt={1.5} sx={{width: '75%'}}>
        <TextInput disabled={isPastDateTime} value={formData.location} label={translate('Where You Meet')} onChange={e => handleInputChange('location', e.target.value)} autoComplete="off" labelVariant="body2" />
      </Box>
    </>
  );
};

export default ChangeMeetingDay;
