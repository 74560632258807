import React, {useEffect, useState} from 'react';
import {Typography, IconButton, Box, FormControl} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import useTranslations from '../../../hooks/useTranslation';
import StyledSelectInput from './StyledSelectInput';
import AdminTextInput from './AdminTextInput';
import Modal from '../../../components/common/Modal';
import {updateOrganization} from '../../../api';
import useAuthStore from '../../../store/authStore';
import IconContainer from '../../../components/common/IconContainer';
import {editsIcon} from '../../../utils/svg';

const OrganizationContactCard = ({data, admindata, reloadData}) => {
  const {translate} = useTranslations();
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    contactUserId: '',
    role: ''
  });
  const [buttonLoader, setButtonLoader] = useState(false);
  const {selectedOrganization} = useAuthStore(state => state);
  const [admins, setAdmins] = useState([]);

  const resetData = () => {
    if (data) {
      setFormData({
        contactUserId: data.contact_user_id || '',
        role: data.contact_user_role || ''
      });
    }
  };

  const handleSubmitClick = async () => {
    setButtonLoader(true);
    try {
      const res = await updateOrganization(
        selectedOrganization.id,
        {
          contact_user_id: formData.contactUserId,
          contact_user_role: formData.role
        },
        'contact'
      );
      if (res?.data?.status === 200) {
        setIsEditing(false);
        reloadData();
      }
      setButtonLoader(false);
    } catch (error) {
      setButtonLoader(false);
    }
  };

  useEffect(() => {
    if (admindata.length > 0) {
      const adminOptions = admindata.map(admin => ({
        value: admin.id,
        label: `${admin.first_name} ${admin.last_name || ''}`
      }));
      setAdmins(adminOptions);
    }
    resetData();
  }, [data, admindata]);

  return (
    <>
      <Box sx={{width: '100%', padding: '16px', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', backgroundColor: '#ffffff'}}>
        <Typography variant="h6" sx={{display: 'flex', justifyContent: 'space-between', color: '#000'}}>
          {translate('Organization Contact')}
          <IconButton onClick={() => setIsEditing(true)} size="small" aria-label="edit">
            <IconContainer icon={editsIcon} width={20} height={20} />
          </IconButton>
        </Typography>
        <Box>
          <Typography variant="h5" sx={{textAlign: 'start', color: '#000', fontWeight: 'bold'}}>
            {data?.contact_user_name || ''}
          </Typography>
          <Typography variant="body1" sx={{color: '#000', textAlign: 'start'}}>
            {translate('Role:')} {data?.contact_user_role || ''}
          </Typography>
          <Typography variant="body1" sx={{color: '#000', textAlign: 'start'}}>
            {translate('Email:')} {data?.contact_user_email || ''}
          </Typography>
          <Typography variant="body1" sx={{color: '#000', textAlign: 'start'}}>
            {translate('Phone:')} {data?.contact_user_mobile || ''}
          </Typography>
        </Box>
      </Box>
      <Modal
        open={isEditing}
        onClose={() => {
          setIsEditing(false);
          resetData();
        }}
        title={translate('Edit Contact Details')}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        buttons={[
          {
            name: translate('Cancel'),
            onClick: () => {
              setIsEditing(false);
              resetData();
            },
            buttonBgColor: '#ffffff',
            buttonTextColor: 'black',
            buttonHoverColor: '#f5f5f5',
            type: 'outlined'
          },
          {
            name: translate('Save'),
            onClick: handleSubmitClick,
            buttonBgColor: '#d32f2f',
            buttonTextColor: '#ffffff',
            buttonHoverColor: '#b71c1c',
            loading: buttonLoader,
            disabled: buttonLoader || !(formData.contactUserId && formData.role.trim())
          }
        ]}
      >
        <StyledSelectInput value={formData?.contactUserId} onChange={e => setFormData(prev => ({...prev, contactUserId: e.target.value}))} optionArray={admins} fullWidth variant="outlined" bottomLabel={translate('Contact Name')} isRequired labelTopGap={-2.5} />
        <FormControl fullWidth sx={{marginTop: '8px'}}>
          <AdminTextInput bottomLabel={translate('Role')} placeholder={translate('Enter Role')} labelVariant="body2" value={formData.role} onChange={e => setFormData(prev => ({...prev, role: e.target.value}))} isRequired />
        </FormControl>
      </Modal>
    </>
  );
};

export default OrganizationContactCard;
