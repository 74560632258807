import {Box, Typography, Button, IconButton} from '@mui/material';
import {useState} from 'react';
import HeaderContainer from '../components/common/HeaderBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BottomBar from '../components/common/BottomBar';
import {useNavigate, Link} from 'react-router-dom';
import HelpOutline from '@mui/icons-material/HelpOutline';
import Modal from '../components/common/Modal';
import ModalHeader from '../components/common/ModalHeader';
import LaunchIcon from '@mui/icons-material/Launch';
import BibleReadingPlan from '../components/modules/ReadingPlan/BibleReadingPlan';
import OutsideReadingPlan from '../components/modules/ReadingPlan/OutsideReadingPlan';
import useTranslations from '../hooks/useTranslation';
import useLeaderStore from '../store/leaderStore';
import {updateBibleReadingPlanApi} from '../api';
import moment from 'moment-timezone';

export default function ReadingPlan() {
  const {translate} = useTranslations();
  const navigate = useNavigate();
  const [selected, setSelected] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [isPlanUpdated, setIsPlanUpdated] = useState(false);
  const meetingId = location?.search.split('=')?.[1];
  const {meetings, setMeetings} = useLeaderStore(state => state);
  const [allBibleOptionsList, setAllBibleOptionsList] = useState([]);
  const [groupMeetings, setGroupMeetings] = useState({
    id: meetings?.id,
    meeting_weeks: meetings?.meeting_weeks,
    starting_time: meetings?.starting_time,
    meeting_day: meetings?.meeting_day,
    meeting_place: meetings?.meeting_place,
    meeting_time: meetings?.meeting_time
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateWeeks = async () => {
    const limit = 52 - (groupMeetings?.meeting_weeks || 42);
    const trulySkipped = allBibleOptionsList.filter(date => date?.isTruly);
    if (trulySkipped.length <= limit) {
      setError(null);
      setLoading(true);

      const doubleUp = allBibleOptionsList.filter(date => date?.isTruly === false);
      const payload = {
        group_id: parseInt(meetingId),
        plan_weeks: groupMeetings?.meeting_weeks,
        bible_reading_start_date: moment(groupMeetings?.starting_time).format('YYYY-MM-DD'),
        bible_reading_skip_weeks: trulySkipped.map(date => moment(date?.date).format('YYYY-MM-DD')).join(','),
        bible_reading_double_up: doubleUp.map(date => moment(date?.date).format('YYYY-MM-DD')).join(',')
      };
      try {
        const res = await updateBibleReadingPlanApi(payload);
        if (res?.data?.status == 200) {
          setSelected(true);
          setMeetings({...meetings, meeting_weeks: groupMeetings?.meeting_weeks});
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      setError(translate('You have reached the maximum number of truly skip weeks.'));
    }
  };

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <Box my={1} px={3}>
        <HeaderContainer pt={1} mt={2}>
          <ArrowBackIcon style={{cursor: 'pointer', color: 'white'}} onClick={() => navigate(-1)} color="white" />
        </HeaderContainer>
        <Box mt={3}>
          <Box mb={2}>
            <Typography sx={{fontSize: '11px'}}>{translate('SET OR EDIT YOUR GROUP')}</Typography>
            <Typography sx={{fontSize: '28px', fontWeight: 'bold'}}>{translate('Reading Plan')}</Typography>
            <IconButton
              style={{
                marginTop: '28px',
                width: '28px',
                height: '28px',
                cursor: 'pointer'
              }}
              onClick={() => setModalOpen(true)}
            >
              <HelpOutline style={{color: 'white'}} />
            </IconButton>
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Button
              sx={{
                backgroundColor: selected ? 'black' : 'white',
                color: selected ? 'white' : 'black',
                width: '45%',
                borderRadius: '25px',
                whiteSpace: 'nowrap',
                fontSize: '12px',
                height: '2rem !important',
                paddingX: '1rem',
                '&:hover': {
                  backgroundColor: selected ? 'black' : 'white'
                },
                '&:focus': {
                  backgroundColor: selected ? 'black' : 'white',
                  color: selected ? 'white' : 'black'
                }
              }}
              onClick={() => {
                setSelected(false);
              }}
            >
              {translate('BIBLE READING PLAN')}
            </Button>
            <Button
              sx={{
                backgroundColor: selected ? 'white' : 'black',
                color: selected ? 'black' : 'white',
                width: '45%',
                borderRadius: '25px',
                whiteSpace: 'nowrap',
                fontSize: '12px',
                height: '2rem !important',
                paddingX: '1rem',
                '&:hover': {
                  backgroundColor: selected ? 'white' : 'black'
                },
                '&:focus': {
                  backgroundColor: selected ? 'white' : 'black',
                  color: selected ? 'black' : 'white'
                }
              }}
              onClick={() => {
                if (isPlanUpdated && !isEditing) {
                  setSelected(true);
                } else {
                  setModalOpen2(true);
                }
              }}
            >
              {translate('OUTSIDE READING')}
            </Button>
          </Box>
          {!selected ? <BibleReadingPlan isEditing={isEditing} setEditing={setEditing} setSelected={setSelected} setIsPlanUpdated={setIsPlanUpdated} isPlanUpdated={isPlanUpdated} allBibleOptionsList={allBibleOptionsList} setAllBibleOptionsList={setAllBibleOptionsList} groupMeetings={groupMeetings} setGroupMeetings={setGroupMeetings} updateWeeks={updateWeeks} /> : <OutsideReadingPlan />}
        </Box>
      </Box>
      <BottomBar
        buttonInputs={[
          {text: translate('Home'), onClick: () => navigate('/leader')},
          {text: '', onClick: () => {}, isShow: false},
          {
            text: translate('Reading'),
            onClick: () => navigate('/leader/meetings'),
            isShow: false
          },
          {text: translate('Settings'), onClick: () => {}}
        ]}
      />
      <Modal
        open={modalOpen2}
        setOpen={setModalOpen2}
        title={translate('Do you want to save changes?')}
        buttons={[
          {name: translate('No'), onClick: () => setModalOpen2(false)},
          {
            name: translate('Yes'),
            onClick: () => {
              setModalOpen2(false);
              setSelected(true);
              updateWeeks();
            }
          }
        ]}
      >
        <Typography sx={{color: 'white'}}>{translate('Are you sure you want to go on outside reading plan? This will update the entries you have filled. Do you want to continue?')}</Typography>
      </Modal>

      <Modal open={modalOpen} setOpen={setModalOpen} buttons={[{name: translate('Done'), onClick: () => setModalOpen(false)}]} style={{height: '100%'}} modalbgColor={'white'}>
        <ModalHeader color="#000" title={translate('Reading Plan Set Up')} />
        <Typography variant="h6" fontWeight="bold" mt={1} color="#000">
          {translate('BIBLE READING PLAN')}
        </Typography>
        <Typography mt={1} color="#000">
          {translate('The app automatically calculates the Bible reading plan based on the number of weeks that your group will meet (from 42 - 48 weeks). However, you can modify that plan for expected or unexpected skipped weeks (think Christmas). You can enter these skips ahead of time or when the need to happen. Skips have two options:')}
        </Typography>
        <ul>
          <li>
            <Typography mt={1} color="#000">
              {translate('• Double Up: This is the norm. Disciples will keep reading both weeks and you will cover two weeks of reading at your next meeting.')}
            </Typography>
          </li>
          <li>
            <Typography mt={1} color="#000">
              {translate('• Truly Skip: This option requires no reading on the skipped week but extends your End Date of Discipleship pushing all reading out by one week.')}
            </Typography>
          </li>
        </ul>
        <Typography variant="h6" fontWeight="bold" mt={1} color="#000">
          {translate('OUTSIDE READING')}
        </Typography>
        <Typography mt={1} color="#000">
          {translate("Outside reading connects the Biblical truths they are learning with real life choices. circumstances and decisions. You can preload the SD standard plan and then rearrange, add books (be careful!) and adjust reading weeks. Here's a few tips.")}
        </Typography>
        <ul>
          <li>
            <Typography mt={1} color="#000">
              {translate('• All the SD Library Books tested for you year of discipleship are in dropdown for each book.')}
            </Typography>
          </li>
          <li>
            <Typography mt={1} color="#000">
              {translate('• The weeks to read are selected via a dropdown ranging from I to 16 (most books are read in 3-6 weeks). Our tested values preload based on selection of book.')}
            </Typography>
          </li>
          <li>
            <Typography mt={1} color="#000">
              {translate("• The 'Weeks Remaining' to read adjusts each time you change a 'time to read' dropdown showing you how many weeks are still available for outside reading.")}
            </Typography>
          </li>
          <li>
            <Typography mt={1} color="#000">
              {translate('• For information on how to use a book, book pairings, etc. visit this link')}
              <Box ml={1} display="inline-flex" sx={{verticalAlign: 'middle'}}>
                <Link to="https://sustainable-discipleship.com/sd-library" target="_blank">
                  <LaunchIcon sx={{color: '#000', mt: '1px', fontSize: '18px'}} />
                </Link>
              </Box>
            </Typography>
          </li>
        </ul>
      </Modal>
    </Box>
  );
}
