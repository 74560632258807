import {Box, Typography, Button, FormControl, RadioGroup, FormControlLabel, Radio, IconButton, Grid, Alert, Tooltip, Menu, MenuItem} from '@mui/material';
import TextInput from '../../common/TextInput';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import Modal from '../../common/Modal';
import {updateBibleReadingPlanApi, getBibleReadingPlanApi, downloadBibleReadingPlan} from '../../../api';
import SelectInput from '../../common/SelectInput';
import useLeaderStore from '../../../store/leaderStore';
import DatePicker from '../../common/DatePicker';
import KeyAndValues from '../../common/KeyAndValues';
import Divide from '../../common/Divide';
import Loader from '../../common/Loader';
import moment from 'moment-timezone';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {CONSTANTS} from '../../../constants';
import {hasValueInArrayObjectProp} from '../../../utils';
import useTranslations from '../../../hooks/useTranslation';
import useAuthStore from '../../../store/authStore';
import DownloadIcon from '@mui/icons-material/Download';

const BibleReadingPlan = ({isEditing, setEditing, setSelected, setIsPlanUpdated, isPlanUpdated, allBibleOptionsList, setAllBibleOptionsList, groupMeetings, setGroupMeetings, updateWeeks}) => {
  const {translate} = useTranslations();
  const location = useLocation();
  const {user} = useAuthStore(state => state);
  const meetingId = location?.search.split('=')?.[1];
  const {meetings, group, setMeetings} = useLeaderStore(state => state);
  const [trulySkipList, setTrulySkipList] = useState(0);
  const [numWeeks, setNumWeeks] = useState(false);
  const [numberWeeks, setNumberWeeks] = useState('');
  const [loading, setLoading] = useState(false);
  const [possibleSkipDates, setPossibleSkipDates] = useState([]);
  const [skipweeks, setSkipWeeks] = useState(0);
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const allWeeks = [
    {label: '42', value: 42},
    {label: '43', value: 43},
    {label: '44', value: 44},
    {label: '45', value: 45},
    {label: '46', value: 46},
    {label: '47', value: 47},
    {label: '48', value: 48}
  ];
  const getBibleReadingPlan = async () => {
    try {
      setLoading(true);
      const response = await getBibleReadingPlanApi(meetingId);
      if (response?.data?.status == 200) {
        setIsPlanUpdated(response.data.data.is_plan_updated);
        const skipWeeks = [...response?.data?.data?.bible_reading_skip_weeks];
        const doubleUpWeeks = [...response?.data?.data?.bible_reading_double_up];
        setPossibleSkipDates(response?.data?.data?.possible_skip_dates || []);
        const formatedSkipWeeks = skipWeeks.map(element => {
          return {
            date: element,
            isTruly: true,
            isOpen: false
          };
        });
        if (formatedSkipWeeks.length > 0) {
          setTrulySkipList(formatedSkipWeeks.length);
        } else {
          setTrulySkipList(4);
        }
        const formatedDoubleUpWeeks = doubleUpWeeks.map(element => {
          return {
            date: element,
            isTruly: false,
            isOpen: false
          };
        });
        const allWeeks = [...formatedSkipWeeks, ...formatedDoubleUpWeeks];
        const sortedAllWeeks = allWeeks.sort((left, right) => {
          return moment.utc(left.date).diff(moment.utc(right.date));
        });
        if (sortedAllWeeks.length > 0) {
          setAllBibleOptionsList(sortedAllWeeks);
        } else {
          setAllBibleOptionsList([
            {date: '', isTruly: '', isOpen: false},
            {date: '', isTruly: '', isOpen: false},
            {date: '', isTruly: '', isOpen: false},
            {date: '', isTruly: '', isOpen: false}
          ]);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleDateChange = (index, newValue) => {
    setError(null);
    setEditing(true);
    const updateDates = allBibleOptionsList.map((open, idx) => {
      if (index === idx) {
        return {...open, date: newValue, isTruly: true};
      } else {
        return open;
      }
    });
    setAllBibleOptionsList(updateDates);
  };

  const handleOpen = index => {
    const updatedOpen = allBibleOptionsList.map((open, idx) => {
      if (index === idx) {
        return {...open, isOpen: true};
      } else {
        return open;
      }
    });
    setAllBibleOptionsList(updatedOpen);
  };

  const handleClose = index => {
    const updatedClose = allBibleOptionsList.map((open, idx) => {
      if (index === idx) {
        return {...open, isOpen: false};
      } else {
        return open;
      }
    });
    setAllBibleOptionsList(updatedClose);
  };

  const handleAddMoreWeeks = () => {
    setError(null);
    let bibleOption = [...allBibleOptionsList];
    bibleOption.push({
      date: null,
      isTruly: '',
      isOpen: false
    });
    setAllBibleOptionsList(bibleOption);
    setTrulySkipList(bibleOption.length);
  };

  const shouldDisableDate = (date, endDate) => {
    let weekNo = 1;
    if (groupMeetings?.meeting_day === 'monday') weekNo = 1;
    if (groupMeetings?.meeting_day === 'tuesday') weekNo = 2;
    if (groupMeetings?.meeting_day === 'wednesday') weekNo = 3;
    if (groupMeetings?.meeting_day === 'thursday') weekNo = 4;
    if (groupMeetings?.meeting_day === 'friday') weekNo = 5;
    if (groupMeetings?.meeting_day === 'saturday') weekNo = 6;
    if (groupMeetings?.meeting_day === 'sunday') weekNo = 0;

    const isWrongDay = new Date(date).getDay() !== weekNo;
    const isAfterEndDate = moment(date).isAfter(endDate);

    if (possibleSkipDates && possibleSkipDates.length > 0) {
      return !possibleSkipDates.some(allowedDate => date.isSame(allowedDate, 'day'));
    }
    return isWrongDay || isAfterEndDate;
  };

  const handleRadioChange = (value, index) => {
    setError(null);
    setEditing(true);
    if (value === 'false') {
      setTrulySkipList(trulySkipList - 1);
    } else {
      setTrulySkipList(trulySkipList + 1);
    }
    const updatedClose = allBibleOptionsList.map((open, idx) => {
      if (index === idx) {
        return {...open, isTruly: value === 'false' ? false : true};
      } else {
        return open;
      }
    });
    setAllBibleOptionsList(updatedClose);
  };

  const resetDateHandler = index => {
    setError(null);
    const updatedList = allBibleOptionsList.map((open, idx) => {
      if (index === idx) {
        return {date: '', isTruly: '', isOpen: false};
      }
      return open;
    });
    setAllBibleOptionsList(updatedList);
  };
  const downloadPlan = async type => {
    try {
      const group_id = group?.id;
      const response = await downloadBibleReadingPlan(type, group_id);
      const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', `bible_reading_plan.${type}`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(blobUrl);
      document.body.removeChild(link);
    } catch (error) {}
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosed = type => {
    setAnchorEl(null);
    if (type) {
      downloadPlan(type);
    }
  };
  useEffect(() => {
    const date = moment(meetings?.starting_time);
    const totalWeeks = groupMeetings?.meeting_weeks || 42;
    const endDate = moment(meetings?.starting_time).add(totalWeeks - 1, 'weeks');
    let current = date; // Find the first Monday
    setPossibleSkipDates([]);
    while (current.isSameOrBefore(endDate)) {
      possibleSkipDates.push(current.format('YYYY-MM-DD'));
      current.add(7, 'days'); // Move to next Monday
    }
    shouldDisableDate(date, endDate);
  }, [groupMeetings?.meeting_weeks]);

  useEffect(() => {
    setError(null);
    getBibleReadingPlan();

    return () => {
      setEditing(false);
    };
  }, []);

  useEffect(() => {
    const skipWeeksList = allBibleOptionsList.filter(item => item.isTruly).length;
    setSkipWeeks(skipWeeksList);
  }, [allBibleOptionsList]);

  return (
    <>
      <Divide />
      <Box>
        {loading ? (
          <Loader />
        ) : (
          <>
            <KeyAndValues name={translate('Start Date')} answer={user.prefered_timezone ? moment(meetings?.starting_time).tz(user.prefered_timezone).format(CONSTANTS.DATE_FORMAT) : moment(meetings?.starting_time).local().format(CONSTANTS.DATE_FORMAT)} />
            <KeyAndValues
              name={translate('Number of Weeks')}
              answer={
                <Box display="flex">
                  <SelectInput
                    sx={{height: '40px', borderRadius: '6px'}}
                    disabled={moment(meetings?.starting_time).isBefore() || (group?.disciple && group?.disciple?.length !== 0)}
                    value={groupMeetings?.meeting_weeks}
                    setValue={value => {
                      setEditing(true);
                      if (hasValueInArrayObjectProp(allBibleOptionsList, 'date')) {
                        setNumWeeks(true);
                        setNumberWeeks(value);
                      } else {
                        setGroupMeetings(prev => {
                          return {
                            ...prev,
                            meeting_weeks: value
                          };
                        });
                      }
                    }}
                    optionArray={allWeeks}
                  />
                  {meetings?.group_week_meetings?.length > 0 && (
                    <>
                      <Tooltip title={translate('Download Options')}>
                        <IconButton aria-label="download" onClick={handleClick}>
                          <DownloadIcon style={{color: 'white'}} />
                        </IconButton>
                      </Tooltip>
                      <Menu id="download-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => handleClosed(null)}>
                        <MenuItem
                          sx={{
                            backgroundColor: 'white',
                            color: 'black',
                            '&:hover': {
                              backgroundColor: 'black',
                              color: 'white'
                            }
                          }}
                          onClick={() => handleClosed('pdf')}
                        >
                          {translate('PDF')}
                        </MenuItem>
                        <MenuItem
                          sx={{
                            backgroundColor: 'white',
                            color: 'black',
                            '&:hover': {
                              backgroundColor: 'black',
                              color: 'white'
                            }
                          }}
                          onClick={() => handleClosed('xlsx')}
                        >
                          {translate('XLSX')}
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </Box>
              }
              comment={translate('Changing the planned number of weeks changes your original plan.')}
            />
            <KeyAndValues
              name={translate('Estimated Finish Date')}
              answer={
                user.prefered_timezone
                  ? moment(meetings?.starting_time)
                      .tz(user.prefered_timezone)
                      .add(!groupMeetings?.meeting_weeks ? 42 + skipweeks : groupMeetings?.meeting_weeks + skipweeks, 'weeks')
                      .format(CONSTANTS.DATE_FORMAT)
                  : moment(meetings?.starting_time)
                      .add(!groupMeetings?.meeting_weeks ? 42 + skipweeks : groupMeetings?.meeting_weeks + skipweeks, 'weeks')
                      .format(CONSTANTS.DATE_FORMAT)
              }
              comment={translate('Each truly skip extends your original finish date of X by one week.')}
            />
            <Divide />

            <Typography textAlign="start" fontSize="12px" my={2}>
              {translate('PLANNED OR UNPLANNED SKIPPED MEETINGS')}
            </Typography>
            {allBibleOptionsList?.map((option, index) => {
              const totalWeeks = groupMeetings?.meeting_weeks || 42;
              const endDate = moment(meetings?.starting_time).add(totalWeeks - 1, 'weeks');
              return (
                <Box key={index} display="flex" justifyContent="space-between" alignItems="center" mb={2} gap={2} sx={{'& input': {width: '55% !important'}}}>
                  <Box display="flex" gap={1} alignItems="center" sx={{width: '50%'}}>
                    <IconButton title={translate('Reset Date')} disabled={possibleSkipDates.length > 0 && !possibleSkipDates.includes(moment(option.date).format('YYYY-MM-DD'))} onClick={() => resetDateHandler(index)}>
                      <RestartAltIcon style={{color: 'white'}} />
                    </IconButton>
                    <DatePicker disabled={option.date && possibleSkipDates.length > 0 && !possibleSkipDates.includes(moment(option.date).format('YYYY-MM-DD'))} open={option.isOpen} setOpen={() => handleOpen(index)} onClose={() => handleClose(index)} value={moment(option.date)} minDate={moment(new Date(meetings?.starting_time))} onChange={newValue => handleDateChange(index, newValue)} showDaysOutsideCurrentMonth renderInput={params => <TextInput {...params} />} shouldDisableDate={date => shouldDisableDate(date, endDate)} />
                  </Box>
                  <Box sx={{width: '50%'}}>
                    <FormControl disabled={!option.date || (possibleSkipDates.length > 0 && !possibleSkipDates.includes(moment(option.date).format('YYYY-MM-DD'))) || moment(new Date(option.date)).isBefore()}>
                      <RadioGroup
                        row
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'nowrap'
                        }}
                        value={option.isTruly}
                        onChange={e => handleRadioChange(e.target.value, index)}
                      >
                        <FormControlLabel
                          sx={{
                            width: '50%',
                            justifyContent: 'space-between',
                            '& .MuiFormControlLabel-label': {
                              fontSize: '0.7rem'
                            },
                            '& .MuiRadio-root': {paddingRight: '0px'},
                            '& .MuiTypography-root': {whiteSpace: 'none'}
                          }}
                          value={false}
                          control={<Radio />}
                          label={translate('Double Up Review')}
                        />
                        <FormControlLabel
                          sx={{
                            width: '50%',
                            justifyContent: 'space-between',
                            '& .MuiFormControlLabel-label': {
                              fontSize: '0.7rem'
                            },
                            '& .MuiRadio-root': {paddingRight: '0px'}
                          }}
                          value={true}
                          control={<Radio />}
                          label={translate('Truly Skip')}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
              );
            })}

            <Grid item xs={12} sx={{height: '55px'}}>
              {error && (
                <Alert style={{textAlign: 'left'}} severity="error">
                  {error}
                </Alert>
              )}
            </Grid>

            <Button
              type="button"
              onClick={() => {
                handleAddMoreWeeks();
              }}
              variant="contained"
              color="primary"
              style={{marginTop: '20px', fontWeight: 400}}
            >
              {translate('Add More Weeks')}
            </Button>
            <Button type="submit" disabled={isPlanUpdated && !isEditing} fullWidth onClick={updateWeeks} variant="contained" color="secondary" style={{marginTop: '20px', fontWeight: 400}}>
              {isPlanUpdated ? translate('Update') : translate('Save')}
            </Button>
          </>
        )}
      </Box>
      <Modal
        open={numWeeks}
        setOpen={setNumWeeks}
        style={{height: '100%', color: 'black'}}
        buttons={[
          {
            name: translate('No'),
            onClick: () => {
              setNumWeeks(false);
              setNumberWeeks('');
            }
          },
          {
            name: translate('Yes'),
            onClick: () => {
              setGroupMeetings(prev => {
                return {
                  ...prev,
                  meeting_weeks: numberWeeks
                };
              });
              setNumWeeks(false);
              getBibleReadingPlan();
            }
          }
        ]}
      >
        <Typography>{translate('Change in weeks will loose your truly skip and double up reading dates')}</Typography>
        <Typography variant="h6">{translate('You want to Continue?')}</Typography>
      </Modal>

      {/* <Modal open={weekLimit} setOpen={setWeekLimit} buttons={[{name: 'OK', onClick: () => setWeekLimit(false)}]} style={{height: '100%', color: 'black'}} title={error}></Modal> */}
    </>
  );
};

export default BibleReadingPlan;
