import {Box, Typography, Grid, Alert, IconButton} from '@mui/material';
import {memo, useCallback, useEffect, useState, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import Modal from '../../common/Modal';
import {getAllPublishedBooks, getAllReadingPlansBooks, getLeaderOutsideReadingApi, updateLeaderOutsideReadingApi, updateOutsideReadingPlanApi} from '../../../api';
import SelectInput from '../../common/SelectInput';
import DeleteIcon from '@mui/icons-material/Delete';
import FormWrapper from '../../common/StyledFormWrapper';
import useLeaderStore from '../../../store/leaderStore';
import Divide from '../../common/Divide';
import Loader from '../../common/Loader';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import {getValueFromBooks} from '../../../utils';
import Button from '../../common/Button';
import {CONSTANTS} from '../../../constants/index';
import useTranslations from '../../../hooks/useTranslation';
import {Add, Download} from '@mui/icons-material';
import AdminTextInput from '../../../pages/admin/components/AdminTextInput';
import useAuthStore from '../../../store/authStore';

const BookNameInput = memo(({customBook, customBookName, handleBookChange, index}) => {
  if (!customBook) return null;
  
  return (
    <Box width="100%" display="flex" justifyContent="space-between" alignItems="center" gap={1}>
      <Box width="65%" display="flex" justifyContent="space-between" alignItems="center" gap={1}>
        <Box width="10%">
          <Typography visibility="hidden">1.</Typography>
        </Box>
        <AdminTextInput 
          height="40px" 
          sx={{height: '20px', border: 'none', borderRadius: '6px', width: '95%', marginLeft: '2px'}} 
          placeholder={'My Custom Book'} 
          value={customBookName} 
          onChange={e => handleBookChange(e.target.value, index, 'customBookName')} 
        />
      </Box>
      <Box width="35%"></Box>
    </Box>
  );
});

const WeekDisplay = memo(({startingTime, startWeek, weeksToRead}) => {
  const {translate} = useTranslations();
  const firstReviewWeek = useMemo(() => 
    moment(startingTime).add(Number(startWeek), 'week').format('YYYY-MM-DD'),
    [startingTime, startWeek]
  );

  const lastReviewWeek = useMemo(() => 
    moment(startingTime)
      .add(Number(weeksToRead) + (Number(startWeek) - 1), 'week')
      .format('YYYY-MM-DD'),
    [startingTime, weeksToRead, startWeek]
  );

  return (
    <Box width="40%">
      <Typography sx={{fontSize: '10px', textAlign: 'right'}}>{translate('First Review Week - ')}{firstReviewWeek}</Typography>
      <Typography sx={{fontSize: '10px', textAlign: 'right'}}>{translate('Last Review Week - ')}{lastReviewWeek}</Typography>
    </Box>
  );
});

const ReadingPlanItem = memo(({option, index, customBookList, handleBookSelect, handleBookChange, handleModal, addBookHandler, meetings}) => {
  const {translate} = useTranslations();
  const handleSelect = useCallback((value) => {
    const bookLabel = getValueFromBooks(customBookList, value, 'label');
    const weeksToRead = getValueFromBooks(customBookList, value, 'weeks_to_read');
    handleBookSelect(bookLabel, index, weeksToRead, value);
  }, [customBookList, handleBookSelect, index]);
  const startDate = useMemo(() => 
    moment(meetings?.starting_time).add(option?.start_week, 'week').subtract(1, 'week').format('YYYY-MM-DD'),
    [meetings?.starting_time, option?.start_week]
  );
  return (
    <Box width="100%">
      <Box width="100%" display="flex" justifyContent="space-between" alignItems="center" gap={1}>
        <Box width="65%" display="flex" justifyContent="space-between" alignItems="center" gap={1}>
          <Box width="10%">
            <Typography sx={{fontSize: '18px', fontWeight: 'normal'}}>{index + 1}.</Typography>
          </Box>
          <SelectInput 
            value={option?.book_id} 
            setValue={handleSelect}
            // setValue={value => handleBookSelect(value, index)} 
            formWidth="90%" 
            sx={{height: '40px', borderRadius: '6px'}} 
            optionArray={customBookList} 
          />
        </Box>
        <Box width="35%" display="flex" justifyContent="space-between" alignItems="center" gap={1}>
          <SelectInput
            formWidth={'50%'}
            value={option?.weeks_to_read}
            sx={{height: '40px', borderRadius: '6px'}}
            setValue={e => handleBookChange(e, index, 'weeks_to_read')}
            optionArray={[...Array(48).keys()].map(i => ({
              label: i + 1,
              value: i + 1
            }))}
          />
          <Box width="50%" display="flex" justifyContent="space-between" alignItems="center">
            <IconButton onClick={() => handleModal('open', option)}>
              <DeleteIcon sx={{color: '#ccc'}} />
            </IconButton>
            <IconButton onClick={() => addBookHandler(index)}>
              <Add sx={{color: '#ccc'}} />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <BookNameInput 
        customBook={option.customBook} 
        customBookName={option.customBookName}
        handleBookChange={handleBookChange}
        index={index}
      />

      <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
        <Box width="60%" display="flex" alignItems="center" gap={1} ml={0.2}>
          <Box width="10%">
            <Typography visibility="hidden">1.</Typography>
          </Box>
          <SelectInput
            formWidth={'30%'}
            value={option?.start_week}
            sx={{height: '40px', borderRadius: '6px'}}
            setValue={e => handleBookChange(e, index, 'start_week')}
            optionArray={[...Array(48).keys()].map(i => ({
              label: i + 1,
              value: i + 1
            }))}
          />
          <Box>
            <Typography sx={{fontSize: '10px', textAlign: 'left'}}>{translate("Pick Start Week")}</Typography>
            <Typography sx={{fontSize: '10px', textAlign: 'left'}}>{startDate}</Typography>
          </Box>
        </Box>
        <WeekDisplay 
          startingTime={meetings?.starting_time}
          startWeek={option?.start_week}
          weeksToRead={option?.weeks_to_read}
        />
      </Box>
    </Box>
  );
});

const OutsideReadingPlan = () => {
  const {translate} = useTranslations();
  const location = useLocation();
  const {meetings, group} = useLeaderStore(state => state);
  const {selectedOrganization} = useAuthStore(state => state);
  const groupId = useMemo(() => location?.search.split('=')?.[1], [location]);
  const navigate = useNavigate();

  const [outsideData, setOutsideData] = useState([]);
  const [customBookList, setCustomBookList] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allReadingPlans, setAllReadingPlans] = useState([]);
  const [selectedReadingPlan, setSelectedReadingPlan] = useState(group?.reading_plan_id ?? null);
  const [readingPlanInfo, setReadingPlanInfo] = useState({});

  const remainingWeeks = useMemo(() => {
    let remaining = readingPlanInfo?.meeting_weeks;
    outsideData.forEach(book => {
      if (!book.ignore_weeks) {
        remaining = remaining - book.weeks_to_read;
      }
    });
    return Number(remaining) < 0 ? 0 : remaining;
  }, [outsideData, readingPlanInfo?.meeting_weeks]);

  const handleModal = useCallback((type, book) => {
    if (type === 'open') {
      setSelectedBook(book);
      setIsModalOpen(true);
    } else {
      setSelectedBook(null);
      setIsModalOpen(false);
    }
  }, []);

  const handleConfirmDelete = useCallback(() => {
    if (selectedBook) {
      setOutsideData(prevData => {
        const newOutsideData = prevData.filter(item => item.sequence_number !== selectedBook.sequence_number);
        if (newOutsideData.length === 0) {
          setError(translate('Reading plan cannot be empty.'));
          return prevData;
        }
        return newOutsideData.map((item, index) => ({
          ...item,
          sequence_number: index + 1
        }));
      });
      handleModal('close');
    }
  }, [selectedBook, translate]);

  const handleBookSelect = useCallback((value, index, weeks, book_id) => {
    setOutsideData(prevData =>
      prevData.map((item, idx) =>
        idx === index
          ? {
              ...item,
              customBook: value === 'Use My Own Custom Book',
              book_name: value,
              weeks_to_read: weeks || '',
              customBookName: '',
              book_id: book_id
            }
          : item
      )
    );
  }, []);

  const handleBookChange = useCallback((value, index, type) => {
    setOutsideData(prevData => {
      const newOutsideData = [...prevData];
      newOutsideData[index] = {
        ...newOutsideData[index],
        [type]: value
      };
      return newOutsideData;
    });
  }, []);

  const addBookHandler = useCallback(
    index => {
      const maxBooks = CONSTANTS[`${group?.level}_MAX_BOOKS`];
      if (!maxBooks) return;

      if (outsideData.length >= maxBooks) {
        setError(translate('You can add a maximum of {maxBooks} books.').replace('{maxBooks}', maxBooks));
        return;
      }

      setOutsideData(prevData => {
        const updatedData = [...prevData];
        updatedData.splice(index + 1, 0, {
          book_name: '',
          weeks_to_read: 1,
          customBook: false,
          start_week: 1,
          end_week: null,
          customBookName: '',
          sequence_number: index + 1
        });
        return updatedData.map((item, i) => ({
          ...item,
          sequence_number: i + 1
        }));
      });
    },
    [outsideData.length, group?.level, translate]
  );

  const hasDuplicateBookNames = useCallback(data => {
    const bookNames = data.map(item => (item.customBook ? item.customBookName : item.book_name));
    const uniqueBookNames = new Set(bookNames);
    return uniqueBookNames.size !== bookNames.length;
  }, []);

  const updateOutsideCustomPlan = useCallback(async () => {
    if (hasDuplicateBookNames(outsideData)) {
      setError(translate('Duplicate book names found. Please ensure all book names are unique.'));
      return;
    }

    const outData = outsideData.map(item => ({
      name: item.customBook ? item.customBookName : item.book_name,
      book_id: item.customBook ? null : item.book_id,
      weeks_to_read: item.weeks_to_read,
      start_week: item.start_week,
      sequence_number: item.sequence_number
    }));

    try {
      setLoading(true);
      const res = await updateLeaderOutsideReadingApi(groupId, readingPlanInfo.id, outData);
      if (res?.data?.status === 200) {
        navigate('/leader');
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [outsideData, groupId, readingPlanInfo.id, hasDuplicateBookNames, translate, navigate]);

  const getAllPublishedBooksHandler = useCallback(async () => {
    try {
      const res = await getAllPublishedBooks(selectedOrganization?.id || '', group.id);
      if (res.data?.status === 200) {
        const customBookData = [
          ...res?.data?.data?.libraryBooks?.map(book => ({
            label: book.book_name,
            value: book.book_id,
            weeks_to_read: book?.weeks_to_read
          })),
          {
            label: translate('Use My Own Custom Book'),
            value: 'Use My Own Custom Book',
            weeks_to_read: 1
          }
        ];
        setCustomBookList(customBookData);
      }
    } catch (error) {
    }
  }, [selectedOrganization?.id, group.id, translate]);

  const getOutsideReadingBooksHandler = useCallback(async (id) => {
    try {
      setLoading(true);
      const res = await getAllReadingPlansBooks(id);
      if (res?.data?.status === 200) {
        setOutsideData(res?.data?.data?.data);
        setReadingPlanInfo(res?.data?.data?.readingPlanInfo);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, []);

  const getAllReadingPlanHandler = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getLeaderOutsideReadingApi(selectedOrganization?.id || '', group.id);
      if (res.data?.status === 200) {
        const plans = res.data?.data?.map(item => ({
          label: item?.name,
          value: item?.id
        }));
        setAllReadingPlans(plans);
        
        const planId = res.data?.data?.[0]?.id;
        if (planId && !selectedReadingPlan) {
          setSelectedReadingPlan(planId);
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [selectedOrganization?.id, group.id, selectedReadingPlan]);

  useEffect(() => {
    getAllPublishedBooksHandler();
    getAllReadingPlanHandler();
  }, []);

  useEffect(() => {
    if (selectedReadingPlan) {
      getOutsideReadingBooksHandler(selectedReadingPlan);
    }
  }, [selectedReadingPlan]);

  const isStartTimeBeforeNow = useMemo(() => 
    moment.utc(meetings.starting_time).isBefore(),
    [meetings.starting_time]
  );

  return (
    <>
      <FormWrapper
        buttonInputs={[
          {
            text: translate('Save'),
            onClick: updateOutsideCustomPlan,
            loading: isStartTimeBeforeNow
          }
        ]}
      >
        <Divide />
        <Box minHeight="fit-content">
          {loading ? (
            <Loader />
          ) : (
            <>
              <Box textAlign="start">
                <Box display="flex" gap={2} alignItems="center" mb={1}>
                  <SelectInput 
                    sx={{height: '40px', borderRadius: '6px'}} 
                    optionArray={allReadingPlans} 
                    value={selectedReadingPlan} 
                    setValue={setSelectedReadingPlan} 
                  />
                  <Typography sx={{fontSize: '13px'}}>{translate('Pick your plan - the adjust weeks to read.')}</Typography>
                </Box>
                <Typography sx={{fontSize: '16px'}}>
                  {translate('Remaining Weeks for Reading: ')}
                  {remainingWeeks}
                </Typography>
                <Box display="flex" gap={2} alignItems="center" justifyContent="space-between" pr={2}>
                  <Typography sx={{fontSize: '11px'}} width="85%" fontStyle="italic">
                    {translate('The remaining weeks of reading is your Total Weeks of Discipleship less all weeks allocated for reading below.')}
                  </Typography>
                </Box>
              </Box>
              <Divide />

              <Box>
                <Box px={4} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography sx={{fontSize: '14px'}} textAlign="start">
                    {translate('Book')}
                  </Typography>
                  <Box mr={'15%'}>
                    <Typography sx={{fontSize: '14px'}}>{translate('Weeks')}</Typography>
                    <Typography sx={{fontSize: '14px'}}>{translate('to Read')}</Typography>
                  </Box>
                </Box>

                {outsideData?.map((option, index) => (
                  <ReadingPlanItem 
                    key={option.sequence_number} 
                    index={index} 
                    option={option} 
                    customBookList={customBookList} 
                    handleBookSelect={handleBookSelect} 
                    handleBookChange={handleBookChange} 
                    handleModal={handleModal} 
                    addBookHandler={addBookHandler} 
                    meetings={meetings} 
                  />
                ))}
              </Box>
            </>
          )}
        </Box>
        <Grid item xs={12} sx={{height: '70px'}}>
          {error && <Alert severity="error">{error}</Alert>}
        </Grid>
      </FormWrapper>

      <Modal color="white" open={isModalOpen} onClose={() => handleModal('close')} title={translate('Confirm Delete')}>
        <Box>
          <Typography variant="body1" mb={2} color="white">
            {translate('Are you sure you want to delete the book')} {selectedBook?.book_name ? `"${selectedBook.book_name}"` : ''}?
          </Typography>
          <Box display="flex" gap={2} justifyContent="flex-end">
            <Button onClick={() => handleModal('close')} text={translate('Cancel')} />
            <Button onClick={handleConfirmDelete} text={translate('Delete')} />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default memo(OutsideReadingPlan);