import React, {useState, useEffect} from 'react';
import FormWrapper from '../../common/StyledFormWrapper';
import Alert from '@mui/material/Alert';
import CssBaseline from '@mui/material/CssBaseline';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, Typography} from '@mui/material';
import {getAssessmentAnswers, getAssessmentsQuestions, postAssessmentsQuestions} from '../../../api';
import {CONSTANTS} from '../../../constants';
import {Grid} from '@mui/material';
import DatePicker from '../../common/DatePicker';
import QuestionHeadings from './QuestionHeadings';
import {mergeConsecutiveSteps} from '../../../utils';
import moment from 'moment';
import useTranslations from '../../../hooks/useTranslation';
import {useParams} from 'react-router-dom';

const PageFour = ({nextpage, user, page, totalPage}) => {
  const {translate} = useTranslations();
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {userId, level} = useParams();
  const [open, setOpen] = useState({
    13: false,
    14: false,
    15: false,
    16: false,
    17: false,
    18: false,
    19: false,
    44: false,
    45: false,
    46: false,
    47: false,
    48: false,
    49: false,
    50: false
  });
  const [maxDateArray, setMaxDateArray] = useState([]);

  const assessmentStepMessages = {
    ASSESSMENT_STEP_MESSAGES: {
      13: translate("They have regular highlights. They are hearing “to dos” and “not dos” from the Word. When asked, 'How do you apply this?' ,they can see a clear action plan and they are adjust to God’s truths each time rather than pushing back."),
      15: translate('Their action based on conviction has shown them a better way. They now know that they don’t know everything. They are hungry to learn and becoming enlightened. The Word is transforming their mind, their thoughts, and decisions.'),
      17: translate('They are feeling compelled to get into the game. Compelled to do something with this thriving faith. They ask for something to do for the Body and are doing it. They are showing up to serve everywhere.'),
      19: translate('They are learning on their own. They have learned to learn and know how to find what they don’t know. They are prepared and confident when it comes to making decisions based on God’s eternal truths.'),
      45: translate('They are all in to follow Christ - however, wherever. No questions asked. If they moved to Timbuktu, they would find a church or start one and follow Christ. They are ready to take the first steps of leadership in the Body of Christ. They are humble, waiting their turn, and want to learn.'),
      47: translate('They have learned to lead and are successfully leading others in the Body of Christ on their own. They have seen that it is a privilege to serve God. They aren’t just serving, they’re servants, waiting on Him, to do only and anything He calls them to do.'),
      49: translate('They are a servant of the Most High King and they realize the Kingdom is not about their home, job, church, or thing. They are invested in His Kingdom so much that they are willing to separate and go serve Him wherever, whenever.')
    }
  };

  const fetchQuestions = async () => {
    try {
      setLoading(true);
      const response = await getAssessmentsQuestions(CONSTANTS.ASSESSMENT_QUESTIONS_IDS.ASSESSMENT_3, user?.id, user?.group);
      if (response?.data?.status === 200) {
        const sortedData = response?.data?.data?.sort((a, b) => a.id - b.id);
        const sortedQuestion = mergeConsecutiveSteps(sortedData);
        setQuestions(sortedQuestion);
        const initialAnswers = sortedData?.map((item, index) => {
          return {
            id: item?.id,
            stepCount: item?.answer?.split('|')[1] ? item?.answer?.split('|')[1] : '',
            answer: item?.answer?.split('|')[0] ? item?.answer?.split('|')[0] : '',
            updated_by: item?.updated_by
          };
        }, {});
        setAnswers(initialAnswers);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const previousAnswer = async () => {
    try {
      const response = await getAssessmentAnswers(userId, CONSTANTS.ASSESSMENT_QUESTIONS_IDS.ASSESSMENT_3);
      if (response.data.status === 200) {
        const responseData = response.data.data;
        const sortedData = responseData?.sort((a, b) => a.question_id - b.question_id);
        const backendAnswers = sortedData.map(item => {
          return {
            id: item.question_id,
            stepCount: item.answer?.split('|')[1] || '',
            answer: item.answer?.split('|')[0] || '',
            updated_by: item.updated_by
          };
        });
        const questionIds = questions.flatMap(q => q.id.split(',').map(Number));
        const existingIds = backendAnswers.map(a => a.id);
        const missingAnswers = questionIds
          .filter(id => !existingIds.includes(id))
          .map(id => ({
            id: id,
            stepCount: '',
            answer: '',
            updated_by: ''
          }));
        const combinedAnswers = [...backendAnswers, ...missingAnswers].sort((a, b) => a.id - b.id);
        setAnswers(combinedAnswers);
      }
    } catch (error) {}
  };

  const handleAnswerChange = (questionId, value) => {
    setError(null);
    const id1 = Number(questionId?.split(',')[0]);
    const id2 = Number(questionId?.split(',')[1]);
    let copiedData = [...answers];
    const index = copiedData.findIndex(obj => Number(obj.id) === id1);
    const index2 = copiedData.findIndex(obj => Number(obj.id) === id2);

    if (index !== -1) {
      copiedData[index]['answer'] = moment(value).format();
    }
    if (index2 !== -1 && copiedData[index2].stepCount) {
      copiedData[index2]['answer'] = moment(value).format();
    }
    setAnswers([...copiedData]);
  };

  function checkFirstItem(array) {
    if (array.length === 0) {
      return false;
    }

    const firstItem = array[0];

    const hasAnswer = firstItem.hasOwnProperty('answer') && firstItem.answer !== '';
    const hasStepCount = firstItem.hasOwnProperty('stepCount') && firstItem.stepCount !== '';

    return hasAnswer || hasStepCount;
  }

  const handleSubmit = async e => {
    e.preventDefault();
    // const isAllQuestionsAnswered = checkFirstItem(answers);
    // if (isAllQuestionsAnswered) {
    await submitAnswers();
    // } else {
    //   setError(CONSTANTS.MESSAGES.ATLEAST_SINGLE_FIELD);
    // }
  };

  const submitAnswers = async () => {
    const data = {
      user_id: user?.id,
      group_id: user?.group,
      questions: answers?.map(data => {
        return {
          question_id: data?.id,
          answer: data?.answer ? `${data?.answer}|${data?.stepCount}` : ''
        };
      })
    };

    try {
      setLoading(true);
      const response = await postAssessmentsQuestions(data);
      if (response?.data?.status === 200) {
        nextpage();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSelectAnswer = (val, id) => {
    setError(null);
    let copiedData = [...answers];
    const index = copiedData.findIndex(obj => Number(obj.id) === Number(id));

    if (index !== -1) {
      // Deselect all answers
      copiedData = copiedData.map(obj => ({
        ...obj,
        stepCount: '',
        answer: obj?.answer ? obj?.answer : ''
      }));

      // Update the selected answer and all previous answers with the current date
      for (let i = 0; i <= index; i++) {
        copiedData[i].answer = copiedData[i].answer ? copiedData[i].answer : moment(new Date(Date.now()));
      }

      // Select the current answer
      copiedData[index].stepCount = val;
    }
    const filterAnswers = copiedData.map((data, i) => {
      if (Number(i) > Number(index)) {
        return {
          ...data,
          answer: ''
        };
      } else {
        return data;
      }
    });
    setAnswers([...filterAnswers]);
  };

  const getIsSelected = ids => {
    let copiedData = answers;
    const id = Number(ids.split(',')[0]);
    const index = copiedData.findIndex(obj => Number(obj.id) === Number(id));
    return answers[index]?.stepCount;
  };

  const getIsSelectedEnd = ids => {
    let copiedData = answers;
    const id = Number(ids.split(',')[1]);
    const index = copiedData.findIndex(obj => Number(obj.id) === Number(id));
    return answers[index]?.stepCount;
  };

  const disableDateHandler = ids => {
    const id1 = Number(ids?.split(',')[0]);
    const id2 = Number(ids?.split(',')[1]);
    const copiedData = [...answers];

    if (copiedData.length === 0) {
      return true;
    }

    // Find the minimum step index
    const minStepIndex = Math.min(id1, id2);

    // Check if any step before minStepIndex has a stepCount set
    const isPreviousStepSelected = () => {
      for (let i = 1; i < minStepIndex; i++) {
        if (copiedData.find(obj => obj.id === i && obj.stepCount)) {
          return false;
        }
      }
      return true;
    };

    return !isPreviousStepSelected();
  };

  const getMinimumDate = (index, value) => {
    let maxDate, minDate;
    if (index === 0) {
      if (maxDateArray[index + 1]) {
        maxDate = maxDateArray[index + 1] || null;
      } else {
        maxDate = moment(new Date());
      }
    } else if (index > 0 && index < 6) {
      minDate = maxDateArray[index - 1] ? moment(maxDateArray[index - 1]) : null;
      if (maxDateArray[index + 1]) {
        maxDate = maxDateArray[index + 1] || null;
      } else {
        maxDate = moment(new Date());
      }
    } else if (index === 6) {
      minDate = maxDateArray[index - 1] ? moment(maxDateArray[index - 1]) : null;
      maxDate = moment(new Date());
    }
    if (value === 'max') {
      return maxDate;
    }
    return minDate;
  };

  const getAnswerValue = ids => {
    let copiedData = answers;
    const id = Number(ids.split(',')[0]);
    const index = copiedData.findIndex(obj => Number(obj.id) === Number(id));
    return moment(answers[index]?.answer);
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  useEffect(() => {
    if (answers.length > 0) {
      let maxDateArrayTemp = [];
      answers.forEach((answer, index) => {
        if (index % 2 === 0) {
          if (answer.answer) {
            maxDateArrayTemp.push(moment(answer.answer));
          } else {
            maxDateArrayTemp.push('');
          }
        }
      });
      setMaxDateArray(maxDateArrayTemp);
    }
  }, [answers]);
  return (
    <>
      <CssBaseline />
      <Box mt={1} px={3} overflow="auto">
        <FormWrapper
          buttonInputs={[
            {
              text: translate('Import Previous Year Assessment'),
              onClick: () => previousAnswer(),
              type: 'button',
              loading: loading,
              hide: level === 'D1'
            },
            {
              text: translate('Continue'),
              onClick: e => handleSubmit(e),
              type: 'submit'
            }
          ]}
          loading={loading}
        >
          <QuestionHeadings heading1={`${translate('ASSESSMENT')} ${page} ${translate('OF')} ${totalPage}`} heading2={translate('WHAT STEP ARE THEY ON TODAY?')} heading3={translate('UPDATE MONTHLY')} />
          <Box my={1}>
            <Box display="flex" justifyContent="space-around" mb={2}>
              <Typography fontSize={12} variant="h6" color="lightgray">
                {translate('CLICK ONE')}
              </Typography>
              <Typography fontSize={12} variant="h6" color="lightgray">
                {translate('WHEN DID THEY TAKE A STEP?')}
              </Typography>
            </Box>
            {questions?.map((ques, index) => {
              return (
                <Box
                  key={ques.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    paddingTop: 0,
                    marginBottom: '10px'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                      width: '30%'
                    }}
                  >
                    <div onClick={() => handleSelectAnswer(ques?.stepCount?.split(',')[0], ques?.id?.split(',')[0])} className={`tap-step-container ${getIsSelected(ques?.id) && 'tap-step-selected'}`}>
                      {ques?.stepCount?.split(',')[0]}
                    </div>
                    <ArrowForwardIcon />
                    <div onClick={() => handleSelectAnswer(ques?.stepCount?.split(',')[1], ques?.id?.split(',')[1])} className={`tap-step-container ${getIsSelectedEnd(ques?.id) && 'tap-step-selected'}`}>
                      {ques?.stepCount?.split(',')[1]}
                    </div>
                  </Box>
                  <Box sx={{width: '70%', paddingTop: 0}} key={index}>
                    <DatePicker
                      open={open[ques?.id?.split(',')[0]]}
                      setOpen={() =>
                        setOpen(prev => ({
                          ...prev,
                          [ques?.id?.split(',')[0]]: true
                        }))
                      }
                      onClose={() =>
                        setOpen(prev => ({
                          ...prev,
                          [ques?.id?.split(',')[0]]: false
                        }))
                      }
                      value={getAnswerValue(ques?.id)}
                      onChange={newValue => handleAnswerChange(ques?.id, newValue + 1)}
                      maxDate={getMinimumDate(index, 'max')}
                      minDate={getMinimumDate(index, 'min')}
                      disabled={disableDateHandler(ques.id, index)}
                      tooltip={assessmentStepMessages.ASSESSMENT_STEP_MESSAGES[ques?.id?.split(',')[0]]}
                      smallDatePickerClass="small-date-picker"
                    />
                    {/* {ques?.updated_by && <Typography pl={2}>{ques?.updated_by !== 'undefined' && getAnswerValue(ques?.id).isValid() ? `added by ${ques?.updated_by}` : ''}</Typography>} */}
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Grid item xs={12} sx={{height: '50px'}}>
            {error && <Alert severity="error">{error}</Alert>}
          </Grid>
        </FormWrapper>
      </Box>
    </>
  );
};

export default PageFour;
