import React, {useState, useEffect} from 'react';
import FormWrapper from '../../common/StyledFormWrapper';
import CssBaseline from '@mui/material/CssBaseline';
import {Box, Typography, Grid} from '@mui/material';
import Alert from '@mui/material/Alert';
import TextInput from '../../common/TextInput';
import {getAssessmentAnswers, getAssessmentsQuestions, postAssessmentsQuestions} from '../../../api';
import {CONSTANTS} from '../../../constants';
import QuestionHeadings from './QuestionHeadings';
import useTranslations from '../../../hooks/useTranslation';
import {useParams} from 'react-router-dom';

const PageSix = ({nextpage, user, page, totalPage}) => {
  const {translate} = useTranslations();
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [error, setError] = useState(null);
  const {userId, level} = useParams();

  const fetchQuestions = async () => {
    try {
      setLoading(true);
      const response = await getAssessmentsQuestions(CONSTANTS.ASSESSMENT_QUESTIONS_IDS.ASSESSMENT_5, user?.id, user?.group);
      if (response?.data?.status === 200) {
        setQuestions(response?.data?.data);
        const initialAnswers = response?.data?.data?.reduce((acc, curr) => {
          acc[curr.id] = curr?.answer || '';
          return acc;
        }, {});
        setAnswers(initialAnswers);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const previousAnswer = async () => {
    try {
      const response = await getAssessmentAnswers(userId, CONSTANTS.ASSESSMENT_QUESTIONS_IDS.ASSESSMENT_5);
      if (response.data.status === 200) {
        const responseData = response.data.data;
        const newAnswers = responseData.reduce((acc, item) => {
          acc[item.question_id] = item.answer || '';
          return acc;
        }, {});

        setAnswers(newAnswers);
      }
    } catch (error) {}
  };

  const handleAnswerChange = (questionId, value) => {
    if (!value) {
      setAnswers(prevAnswers => ({
        ...prevAnswers,
        [27]: ''
      }));
    }
    setError(null);
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: value
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const isAllQuestionsAnswered = true;
    if (isAllQuestionsAnswered) {
      await submitAnswers();
    } else {
      setError(translate('All fields are required!'));
    }
  };

  const submitAnswers = async () => {
    const data = {
      user_id: user?.id,
      group_id: user?.group,
      questions: Object.entries(answers).map(([questionId, answer]) => ({
        question_id: questionId,
        answer: answer.trim()
      }))
    };
    try {
      setLoading(true);
      const response = await postAssessmentsQuestions(data);
      if (response?.data?.status === 200) {
        nextpage();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  return (
    <>
      <CssBaseline />
      <Box mt={1} px={3} overflow="auto">
        <FormWrapper
          buttonInputs={[
            {
              text: translate('Import Previous Year Assessment'),
              onClick: () => previousAnswer(),
              type: 'button',
              loading: loading,
              hide: level === 'D1'
            },
            {
              text: translate('Continue'),
              onClick: e => handleSubmit(e),
              type: 'submit'
            }
          ]}
          // headingText={`Assessment ${page} of ${totalPage}`}
          loading={loading}
        >
          <QuestionHeadings heading1={`${translate('ASSESSMENT')} ${page} ${translate('OF')} ${totalPage}`} heading2={translate('PREPARING FOR RESIDENCY?')} heading3={translate('FIRST QUARTER D3')} />
          <Box>
            <Box display="flex" alignItems="center" flexWrap="wrap" textAlign="left" alignSelf="start">
              <Typography mr={1} variant="body3">
                {translate('Being Called')}
              </Typography>
              <div
                style={{
                  borderRadius: '50%',
                  height: '0.5rem',
                  width: '0.5rem',
                  backgroundColor: 'white',
                  marginRight: '8px'
                }}
              ></div>
              <Typography mr={1} variant="body3">
                {translate('Serving')}
              </Typography>
              <div
                style={{
                  borderRadius: '50%',
                  height: '0.5rem',
                  width: '0.5rem',
                  backgroundColor: 'white',
                  marginRight: '8px'
                }}
              ></div>
              <Typography mr={1} variant="body3">
                {translate('Residency')}
              </Typography>
              <div
                style={{
                  borderRadius: '50%',
                  height: '0.5rem',
                  width: '0.5rem',
                  backgroundColor: 'white',
                  marginRight: '8px'
                }}
              ></div>
              <Typography mr={1} variant="body3">
                {translate('Leading')}
              </Typography>
              <div
                style={{
                  borderRadius: '50%',
                  height: '0.5rem',
                  width: '0.5rem',
                  backgroundColor: 'white',
                  marginRight: '8px'
                }}
              ></div>
              <Typography variant="body3">{translate('Going/Sending')}</Typography>
            </Box>
            <Box mt={2}>
              {questions.map(question => {
                if (question.id === 27 && !answers[25]) {
                  return <Box key={question?.id} height={90}></Box>;
                }
                return <TextInput label={question?.question} key={question.id} placeholder={question.question} value={answers[question.id] || ''} onChange={e => handleAnswerChange(question.id, e.target.value)} labelVariant="body2" disabled={question.id === 27 && !answers[25]} />;
              })}
            </Box>
          </Box>
          <Grid item xs={12} sx={{height: '50px'}}>
            {error && <Alert severity="error">{error}</Alert>}
          </Grid>
        </FormWrapper>
      </Box>
    </>
  );
};

export default PageSix;
