import axios from 'axios';
import {BASE_URL} from '../constants';
import {toast} from 'react-hot-toast';
import useTranslations from '../hooks/useTranslation';
const {translate} = useTranslations();

const state = JSON.parse(localStorage.getItem('auth-storage'));
const API = axios.create({
  baseURL: BASE_URL
});

// FOR ADDING HEADER TO EACH REQUESTS
API.interceptors.request.use(req => {
  req.headers['Content-Type'] = 'application/json';
  if (state?.state?.token) {
    req.headers['Authorization'] = state?.state?.token;
  }
  return req;
});

API.interceptors.response.use(
  async res => {
    if (res?.data?.message !== 'success' && res?.request?.responseType !== 'blob') {
      toast.success(translate(res?.data?.message), {id: 'toast_message'});
    }
    return res;
  },
  async error => {
    if (!(String(error?.config?.url).startsWith('/zipcode') && error?.config?.method === 'get')) {
      toast.error(translate(error?.response?.data?.message) || translate('Something went wrong!'), {id: 'toast_message'});
    }
    if (error.response.status === 422) {
      const errors = error.response?.data;
      const firstErrorKey = Object.keys(errors)[0];
      const firstErrorValue = errors[firstErrorKey];
      toast.error(translate(String(firstErrorValue)), {id: 'toast_message'});
    }
    if (error.response.status === 500) {
      toast.error(translate('Something went wrong!'), {id: 'toast_message'});
      localStorage.clear();
      window.location.reload();
    }
    return error;
  }
);

// AUTH API'S
const loginUserApi = data => {
  return API.post('/login', data);
};
const checkInviteUser = inviteCode => {
  return API.get(`/signup/${inviteCode}`);
};
const registerInvitedUser = (inviteCode, data) => {
  return API.patch(`/signup/${inviteCode}`, data);
};
const registerNonInvitedUser = data => {
  return API.post(`/signup`, data);
};
const otpVerificationApi = data => {
  return API.post('/email-verification', data);
};
const resetPasswordSendOTPApi = email => {
  return API.get(`reset-password/${email}`);
};
const verifyAndResetPasswordApi = data => {
  return API.post('reset-password', data);
};
const changePasswordApi = data => {
  return API.post('change-password', data);
};

// USER API'S
const getUsersDetails = userId => {
  return API.get(`/users?userId=${userId}`);
};
const updateUsersDetails = userId => {
  return API.patch(`/users/${userId}`);
};
const inviteUser = data => {
  return API.post('/users', data);
};
const deleteUser = usersid => {
  return API.delete(`/users/${usersid}`);
};
const deleteInvitationOfUserApi = id => {
  return API.delete(`/users/${id}`);
};

// ASSESSMENT API'S
const getAssessmentsQuestions = (questions_ids, user_id, group_id) => {
  return API.get(`/questions?questions_ids=${questions_ids}&user_id=${user_id}&group_id=${group_id}`);
};
const getDiscipleQuestions = (questions_ids, user_id) => {
  return API.get(`/experience/${user_id}?questions_ids=${questions_ids}`);
};
const postAssessmentsQuestions = data => {
  return API.post('/assessment', data);
};
const getAssessmentAnswers = (user_id, question_ids) => {
  return API.get(`/assessment?question_ids=${question_ids}&user_id=${user_id}`);
};

// GROUP API'S
const getGroupApi = (id, user_id = '') => {
  return API.get(`/group?group_id=${id}&user_id=${user_id}`);
};
const updateGroupApi = (id, data, is_leader_update = '') => {
  return API.patch(`/group/${id}?is_leader_update=${is_leader_update}`, data);
};
const deleteGroupApi = (id, type = '') => {
  return API.delete(`/group/${id}?type=${type}`);
};
const archiveGroupApi = (id, data, is_leader_update = '') => {
  return API.patch(`/group/${id}?is_leader_update=${is_leader_update}`, data);
};
const getLeadersGroup = () => {
  return API.get('/leaders-groups');
};
const inviteDiscipleApi = data => {
  return API.post('/group-disciple', data);
};
const sendInviteToDisciple = data => {
  return API.post('/send-invite', data);
};
const getGroupDiscipleApi = (id, orgId = '') => {
  return API.get(`/group-disciple?group_id=${id}&orgId=${orgId}`);
};

// ORGANIZATIONS API'S
const addOrganization = data => {
  return API.post('/organization', data);
};
const deleteOrganization = id => {
  return API.delete(`/organization/${id}`);
};
const getOrganizationsApi = (id = '', is_public = false) => {
  return API.get(`/organization?user_id=${id}&is_public=${is_public}`);
};
const showOrganizationsApi = (id = '', name) => {
  return API.get(`/organization/${id}?name=${name}`);
};
const sendMeetingInvite = data => {
  return API.post('/group-meetings', data);
};
const updateMeetingApi = (id, data) => {
  return API.patch(`/group-meetings/${id}`, data);
};
const getMeetings = (id, user_id = '', requested_sequence = '', type = '') => {
  return API.get(`/group-meetings?group_id=${id}&user_id=${user_id}&requested_sequence=${requested_sequence}&type=${type}`);
};
const getOutsideReadingPlanApi = id => {
  return API.get(`/group-outside-reading-plan/${id}`);
};
const updateOutsideReadingPlanApi = data => {
  return API.post('/group-outside-reading-plan', data);
};
const getBibleReadingPlanApi = id => {
  return API.get(`/group-bible-reading-plan/${id}`);
};
const updateBibleReadingPlanApi = data => {
  return API.post('/group-bible-reading-plan', data);
};
const postUpdateBibleReadingPlanApi = (id, data) => {
  return API.patch(`/group-bible-reading-plan/${id}`, data);
};
const updateOrganization = (id, data, name) => {
  return API.patch(`organization/${id}?name=${name}`, data);
};
const updatethemeOrganization = (id, theme_id, istheme) => {
  return API.patch(`organization/${id}`, {theme_id, istheme});
};
const updateOrganizationDefaultLanguage = (id, selectedLang) => {
  return API.patch(`organization/${id}`, {default_language: parseInt(selectedLang)});
};
const updateUserPreferedLangApi = (id, preferedLang) => {
  return API.patch(`user-prefered-lang/${id}`, {user_prefered_lang: parseInt(preferedLang)});
};

// LEADER API'S
const addExperienceApi = data => {
  return API.post('/experience', data);
};
const getExperienceApi = ids => {
  return API.get(`/experience?questions_ids=${ids}`);
};
const addAddressApi = data => {
  return API.post('/address', data);
};
const getAddressApi = id => {
  return API.get(`/address?disciple_id=${id}`);
};
const updateAddressApi = data => {
  return API.patch('/address/123', data);
};
const getAddressByZipcode = zipcode => {
  return API.get(`/zipcode?zipcode=${zipcode}`);
};

const getReportApi = data => {
  return API.post(`/report`, data);
};

// disciple apis
const deleteDiscipleApi = (id, isDiscipleDrop = false) => {
  return API.delete(`/group-disciple/${id}?isDiscipleDrop=${isDiscipleDrop}`);
};

// send email apis
const sendEmailApi = (data, type) => {
  return API.post(`/send-email?send_to=${type}`, data);
};

// reading plan apis
const discipleReadingApi = (requestedWeek, type) => {
  return API.get(`/disciple-reading/${requestedWeek}?type=${type}`);
};

const downloadBibleReadingPlan = (type, groupId = '') => {
  return API.get(`/download-bible-reading-plan?type=${type}&group_id=${groupId}`, {
    responseType: 'blob'
  });
};
const markChapterReadApi = (data, type) => {
  return API.post(`/disciple-reading?type=${type}`, data);
};

const getBadges = (group_disciples_id, group_id) => {
  return API.get(`/badges/${group_disciples_id}?gp_id=${group_id}`);
};

const addDisciple = data => {
  return API.post('/group-disciple', data);
};
const updateDiscipleApi = (group_disciple_id, data) => {
  return API.patch(`/group-disciple/${group_disciple_id}`, data);
};

const activateDisciple = data => {
  return API.post('/activate-disciple', data);
};

// update user role apis
const addUserRoleApi = data => {
  return API.post(`/user-role`, data);
};
const removeUserRoleApi = (id, permission, orgId) => {
  return API.patch(`/user-role/${id}`, {
    permission,
    orgId
  });
};

// admin dashboard api
const getAdminData = async orgId => {
  return API.get(`admin?organization_id=${orgId}`);
};

const getDashboardData = async (org_id = '') => {
  return API.get(`dashboard?org_id=${org_id}`);
};

const updateUserRole = async (userId, data) => {
  return API.patch(`update-user-role/${userId}`, data);
};

const getDiscipleDetailsApi = async (user_id, name) => {
  return API.get(`admin-disciple?user_id=${user_id}&name=${name}`);
};

const getLeaderDiscipleDetailsApi = async (user_id, name, orgId) => {
  return API.get(`admin-disciple/${user_id}?name=${name}&organization_id=${orgId}`);
};

const getAdminLeadersGroupApi = (id, details = '', organization = '') => {
  return API.get(`/leaders-groups/${id}?isShow=${details}&organization=${organization}`);
};

const getAdminPromoteDiscipleApi = (org_id = '') => {
  return API.get(`/promote-invite-leader/?org_id=${org_id}`);
};
const addLeaderApi = data => {
  return API.post('/promote-invite-leader', data);
};
const getAdminOrgDisciplesApi = (orgId, name, isNominated = false) => {
  return API.get(`/group-disciple/${orgId}?name=${name}&is_nominated=${isNominated}`);
};

const getAllDisciples = () => {
  return API.get(`/group-disciple`);
};
const getAdminGroupsApi = (orgId, name = '') => {
  return API.get(`/group/${orgId}?name=${name}`);
};
const markAttendenceOfGroupMeeting = data => {
  return API.post(`/meeting-attendance`, data);
};
const getAttendenceOfGroupMeeting = (meeting_id, discipleId = '') => {
  return API.get(`/meeting-attendance/${meeting_id}?disicpleId=${discipleId}`);
};
const getAllReadingPlansApi = (orgId = '', name = '') => {
  return API.get(`/reading-plans?orgId=${orgId}&name=${name}`);
};
const updateReadingPlansApi = (id, data, isCheck = '', name = '') => {
  return API.patch(`/reading-plans/${id}?is_check=${isCheck}&name=${name}`, data);
};

const getAllAvailableBooks = (org_id = '', year = '') => {
  return API.get(`/outside-books?orgId=${org_id}&year=${year}`);
};

const getAllReadingPlansBooks = id => {
  return API.get(`/reading-plan-books/${id}`);
};
const addPlanApi = (data, is_duplicate = '') => {
  return API.post(`/reading-plans?is_duplicate=${is_duplicate}`, data);
};
const deletePlanApi = id => {
  return API.delete(`/reading-plans/${id}`);
};

const addBookApi = data => {
  return API.post(`/outside-books`, data);
};

const updateBookApi = (id, data) => {
  return API.patch(`/outside-books/${id}`, data);
};

const deleteBookApi = (id, data) => {
  return API.delete(`/outside-books/${id}`);
};
const getAllPublishedBooks = (org_id = '', groupId = '') => {
  return API.get(`/organization-published-outside-books?orgId=${org_id}&groupId=${groupId}`);
};
const addBookInReadingPlanHandler = data => {
  return API.post(`/reading-plan-books`, data);
};
const updatePublishedBooksApi = (id, data) => {
  return API.patch(`/organization-published-outside-books/${id}`, data);
};
const promoteUserHandlerApi = (id, data) => {
  return API.patch(`/leader-level/${id}`, data);
};
const getLeaderOutsideReadingApi = (orgId = '', groupId = '') => {
  return API.get(`/leader-outside-reading?orgId=${orgId}&groupId=${groupId}`);
};
const updateLeaderOutsideReadingApi = (groupId, planId, data) => {
  return API.post(`/leader-outside-reading?groupId=${groupId}&planId=${planId}`, data);
};
const getAllUsersApi = (orgId) => {
  return API.get(`/all-user?organization=${orgId}`);
}

export {
  sendEmailApi,
  loginUserApi,
  checkInviteUser,
  registerInvitedUser,
  registerNonInvitedUser,
  otpVerificationApi,
  resetPasswordSendOTPApi,
  verifyAndResetPasswordApi,
  getUsersDetails,
  getAssessmentsQuestions,
  postAssessmentsQuestions,
  getGroupApi,
  updateGroupApi,
  deleteGroupApi,
  inviteDiscipleApi,
  sendInviteToDisciple,
  getLeadersGroup,
  inviteUser,
  addOrganization,
  getOrganizationsApi,
  updateOrganization,
  sendMeetingInvite,
  addAddressApi,
  addExperienceApi,
  getExperienceApi,
  getAddressApi,
  updateAddressApi,
  getAddressByZipcode,
  getReportApi,
  deleteDiscipleApi,
  updateMeetingApi,
  deleteInvitationOfUserApi,
  getMeetings,
  getOutsideReadingPlanApi,
  updateOutsideReadingPlanApi,
  discipleReadingApi,
  deleteUser,
  getBibleReadingPlanApi,
  updateBibleReadingPlanApi,
  downloadBibleReadingPlan,
  addUserRoleApi,
  removeUserRoleApi,
  markChapterReadApi,
  postUpdateBibleReadingPlanApi,
  getAdminData,
  updateUsersDetails,
  updateUserRole,
  getAdminOrgDisciplesApi,
  getDiscipleDetailsApi,
  getAdminLeadersGroupApi,
  getGroupDiscipleApi,
  getLeaderDiscipleDetailsApi,
  getAdminGroupsApi,
  getDashboardData,
  getDiscipleQuestions,
  markAttendenceOfGroupMeeting,
  getAttendenceOfGroupMeeting,
  getBadges,
  changePasswordApi,
  updatethemeOrganization,
  getAllDisciples,
  getAllReadingPlansApi,
  updateOrganizationDefaultLanguage,
  addDisciple,
  activateDisciple,
  getAllAvailableBooks,
  getAllPublishedBooks,
  addBookApi,
  updateBookApi,
  deleteBookApi,
  updatePublishedBooksApi,
  updateReadingPlansApi,
  getAllReadingPlansBooks,
  deletePlanApi,
  addPlanApi,
  addBookInReadingPlanHandler,
  promoteUserHandlerApi,
  updateDiscipleApi,
  getLeaderOutsideReadingApi,
  updateLeaderOutsideReadingApi,
  showOrganizationsApi,
  getAdminPromoteDiscipleApi,
  addLeaderApi,
  archiveGroupApi,
  updateUserPreferedLangApi,
  deleteOrganization,
  getAssessmentAnswers,
  getAllUsersApi
};
