import {create} from 'zustand';
import {persist, createJSONStorage} from 'zustand/middleware';

const authStore = (set, get) => ({
  user: null,
  token: null,
  preferedTimezone: null,
  rememberEmail: '',
  selectedOrganization: null,
  org_to_theme: null,
  theme: null,
  user_prefered_lang: null, // Add preferred language state

  loginUser: userData => {
    set(state => ({
      ...state,
      user: userData,
      token: userData.authToken,
      preferedTimezone: userData.prefered_timezone,
      user_prefered_lang: userData.user_prefered_lang
    }));
  },
  logoutUser: () => {
    set(state => ({
      ...state,
      user: null,
      token: null,
      preferedTimezone: null,
      selectedOrganization: null,
      user_prefered_lang: null
    }));
  },
  setRememberEmail: email => {
    set(state => ({
      ...state,
      rememberEmail: email
    }));
  },
  updateUserData: data => {
    set(state => ({
      ...state,
      user: {...state.user, ...data}
    }));
  },
  updateOrgDefaultLanguage: data => {
    set(state => ({
      ...state,
      selectedOrganization: {...state.selectedOrganization, ...data}
    }));
  },
  loginNewUser: data => {
    set(state => ({
      ...state,
      token: data?.authToken,
      user: data
    }));
  },
  setOrganization: data => {
    set(state => ({
      ...state,
      selectedOrganization: data
    }));
  },
  setOrgToTheme: data => {
    set(state => ({
      ...state,
      org_to_theme: data
    }));
  },
  setTheme: data => {
    set(state => ({
      ...state,
      theme: data
    }));
  },

  updateOrganizationState: (id, avatar, name) => {
    set(state => ({
      ...state,
      selectedOrganization: {...state.selectedOrganization, ...(avatar && {avatar: avatar}), ...(name && {name: name})},
      user: {
        ...state.user,
        organizations: state.user?.organizations?.map(org => {
          if (org.id == id) {
            return {
              ...org,
              ...(name && {name: name}),
              ...(avatar && {avatar: avatar})
            };
          } else {
            return org;
          }
        })
      }
    }));
  },
  updateUserPreferedLang: (lang) => {
    set((state) => ({
      ...state,
      user_prefered_lang: lang,
      user: state.user ? { ...state.user, user_prefered_lang: lang } : null,
    }));
    window.location.reload();
  },
  
});

const useAuthStore = create(
  persist(authStore, {
    name: 'auth-storage',
    storage: createJSONStorage(() => localStorage)
  })
);

export default useAuthStore;
