import React, {useEffect, useState} from 'react';
import {Box, Checkbox, FormControlLabel, FormGroup, Typography} from '@mui/material';
import AdminStructure from './common/AdminStructure';
import useTranslations from '../../hooks/useTranslation';
import useAuthStore from '../../store/authStore';
import Loader from '../../components/common/Loader';
import {getAllUsersApi} from '../../api';
import ItemList from './common/ItemList';
import ItemDetails from './common/Details';
import moment from 'moment';
import {CONSTANTS} from '../../constants';
import LeaderIcon from '../../images/letter-l.png';
import AdminIcon from '../../images/letter-a.png';
import DiscipleIcon from '../../images/letter-d.png';

export const AllUsers = () => {
  const {translate} = useTranslations();
  const {selectedOrganization} = useAuthStore(state => state);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState({admin: [], leader: [], disciple: []});
  const [selectedRoles, setSelectedRoles] = useState({
    all: true,
    admin: true,
    leader: true,
    disciple: true
  });
  const [selectedUser, setSelectedUser] = useState(null);
  const [loader, setLoader] = useState(false);

  const handleUserClick = user => {
    setLoader(true);
    setSelectedUser(user);
    setLoader(false);
  };
  const fetchData = async () => {
    try {
      setLoading(true);

      if (!selectedOrganization?.id) {
        return;
      }
      const response = await getAllUsersApi(selectedOrganization.id);
      setUsers({
        admin: response?.data?.data?.admin?.active ?? [],
        leader: response?.data?.data?.leader?.active ?? [],
        disciple: response?.data?.data?.disciple?.active ?? []
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedOrganization?.id) {
      fetchData();
    }
  }, [selectedOrganization]);
  useEffect(() => {
    setSelectedUser(null);
  }, [selectedRoles]);

  const handleCheckboxChange = async (role) => {
    const updatedRoles = { ...selectedRoles, [role]: !selectedRoles[role] };
  
    if (role === 'all') {
      const newState = !selectedRoles.all;
      updatedRoles.all = newState;
      updatedRoles.admin = newState;
      updatedRoles.leader = newState;
      updatedRoles.disciple = newState;
    } else {
      updatedRoles.all = updatedRoles.admin && updatedRoles.leader && updatedRoles.disciple;
    }
      setSelectedRoles(updatedRoles);
    // Fetch only user list without reloading entire component
    if (selectedOrganization?.id) {
      try {
        const response = await getAllUsersApi(selectedOrganization.id);
        setUsers({
          admin: response?.data?.data?.admin?.active ?? [],
          leader: response?.data?.data?.leader?.active ?? [],
          disciple: response?.data?.data?.disciple?.active ?? [],
        });
      } catch (error) {
      }
    }
  };

  // Get selected role names for dynamic heading
  const selectedRoleNames = Object.keys(selectedRoles)
    .filter(role => selectedRoles[role] && role !== 'all')
    .map(role => role.charAt(0).toUpperCase() + role.slice(1));

  const roleNames = Object.entries(CONSTANTS.USER_ROLES).reduce((acc, [key, value]) => {
    acc[value] = key; // Reverse mapping: {0: "SUPERADMIN", 1: "ADMIN", 2: "LEADER", 3: "DISCIPLE"}
    return acc;
  }, {});

  const roleIcons = {
    DISCIPLE: <img src={DiscipleIcon} alt="D" style={{width: '16px', height: '16px'}} />,
    LEADER: <img src={LeaderIcon} alt="L" style={{width: '16px', height: '16px'}} />,
    ADMIN: <img src={AdminIcon} alt="A" style={{width: '16px', height: '16px'}} />
  };

  return (
    <div
      style={{
        backgroundImage: 'none !important',
        backgroundColor: '#f9f9f9',
        height: '100vh',
        width: '100vw'
      }}
    >
      <AdminStructure heading={translate('Users')}>
        {loading ? (
          <Loader color="#9e0001" />
        ) : (
          <>
            <div style={{margin: '0px 10px 10px 30px', textAlign: 'left', maxHeight: '60%'}}>
              {/* Checkboxes for filtering */}
              <FormGroup row>
                <FormControlLabel control={<Checkbox checked={selectedRoles.all} onChange={() => handleCheckboxChange('all')} sx={{color: 'black', '&.Mui-checked': {color: 'black'}}} />} label={<Typography style={{color: '#000'}}>{translate('All')}</Typography>} />
                <FormControlLabel control={<Checkbox checked={selectedRoles.admin} onChange={() => handleCheckboxChange('admin')} sx={{color: 'black', '&.Mui-checked': {color: 'black'}}} />} label={<Typography style={{color: '#000'}}>{translate('Admin')}</Typography>} />
                <FormControlLabel control={<Checkbox checked={selectedRoles.leader} onChange={() => handleCheckboxChange('leader')} sx={{color: 'black', '&.Mui-checked': {color: 'black'}}} />} label={<Typography style={{color: '#000'}}>{translate('Leader')}</Typography>} />
                <FormControlLabel control={<Checkbox checked={selectedRoles.disciple} onChange={() => handleCheckboxChange('disciple')} sx={{color: 'black', '&.Mui-checked': {color: 'black'}}} />} label={<Typography style={{color: '#000'}}>{translate('Disciple')}</Typography>} />
              </FormGroup>
            </div>

            <Box display="flex" px={2} gap={2} sx={{flexDirection: {md: 'row', sm: 'column', xs: 'column'}}}>
              {!Object.values(selectedRoles).includes(true) ? (
                <Typography color="#000000" fontWeight={'bold'} sx={{ml: 2}}>
                  {translate('Please select a checkbox')}
                </Typography>
              ) : (
                <>
                  <Box width="85%" height="60%" display="flex" flexDirection="column" gap={2} sx={{overflowY: 'auto', maxHeight: '600px'}}>
                    <ItemList
                      items={Object.keys(users)
                        .filter(role => selectedRoles[role])
                        .flatMap(role => users[role].filter(user => user.status === 'ACTIVE'))
                        .map(user => ({
                          id: user.id,
                          name: (
                            <Box sx={{display: 'flex', alignItems: 'center', gap: 0.5, width: '100%', backgroundColor: selectedUser && selectedUser.id === user.id ? '#fae6e7' : 'transparent', padding: '4px'}}>
                              <span>{user.name}</span>
                              {user.user_permissions.split(',').map(perm => {
                                const role = Object.keys(CONSTANTS.USER_ROLES).find(key => CONSTANTS.USER_ROLES[key] === parseInt(perm, 10));
                                return role ? roleIcons[role] : null;
                              })}
                            </Box>
                          ),
                          user
                        }))}
                      handleClick={userItem => handleUserClick(userItem.user)}
                      title={selectedRoleNames.map(role => `${translate(role + 's')} (${users[role.toLowerCase()].filter(user => user.status === 'ACTIVE').length})`).join(' & ')}
                      selectedItem={selectedUser}
                      height={'100%'}
                    />
                  </Box>

                  <Box width="68%" display="flex" flexDirection="column" gap={2}>
                    {selectedUser && (
                      <ItemDetails
                        title={selectedUser.name}
                        details={[
                          {name: translate('Role:'), detail: selectedUser.user_role},
                          {name: translate('Status:'), detail: selectedUser.status},
                          {name: translate('Birthday:'), detail: selectedUser.birthday ? moment(selectedUser.birthday).format('MMMM Do, YYYY') : translate('Not provided'), mb: '8px'},
                          {name: translate('Created At:'), detail: moment(selectedUser.created_at).format('MMMM Do YYYY, HH:mm:ss')},
                          {name: translate('Last Login:'), detail: selectedUser.last_login ? moment(selectedUser.last_login).format('MMMM Do YYYY, HH:mm:ss') : translate('Never logged in'), mb: '8px'},
                          {
                            name: translate('User Access:'),
                            detail: selectedUser.user_permissions
                              .split(',') // Convert "1,2,3" to an array
                              .map(perm => roleNames[parseInt(perm, 10)]) // Convert numbers to role names
                              .join(', '), // Convert back to string
                            mb: '8px'
                          },
                          {name: translate('Contact Information:')},
                          {name: translate(''), detail: selectedUser.mobile_number},
                          {name: translate(''), detail: selectedUser.email}
                        ]}
                        height="40%"
                        loader={loader}
                      />
                    )}
                  </Box>
                </>
              )}
            </Box>
          </>
        )}
      </AdminStructure>
    </div>
  );
};

export default AllUsers;
