import React, {useEffect, useState} from 'react';
import {IconButton, Typography, Box, FormControl} from '@mui/material';
import useTranslations from '../../../hooks/useTranslation';
import AdminTextInput from './AdminTextInput';
import Modal from '../../../components/common/Modal';
import {updateOrganization} from '../../../api';
import useAuthStore from '../../../store/authStore';
import IconContainer from '../../../components/common/IconContainer';
import {editsIcon} from '../../../utils/svg';
import {CONSTANTS} from '../../../constants';

const GroupTitle = ({data, reloadData}) => {
  const {translate} = useTranslations();
  const {selectedOrganization} = useAuthStore(state => state);
  const [isEditing, setIsEditing] = useState(false);
  const [group_title, setTitle] = useState('');
  const [buttonLoader, setButtonLoader] = useState(false);

  const resetData = () => {
    setTitle(data.group_title || '');
  };

  const handleSubmitClick = async () => {
    setButtonLoader(true);
    try {
      const res = await updateOrganization(selectedOrganization.id, {group_title}, 'group_title');
      if (res?.data?.status === 200) {
        setIsEditing(false);
        reloadData();
      }
      setButtonLoader(false);
    } catch (error) {
      setButtonLoader(false);
    }
  };

  useEffect(() => {
    resetData();
  }, [data]);

  return (
    <>
      <Box
        sx={{
          width: selectedOrganization.id == CONSTANTS.SD_INDIVIDUAL_ORGANIZATION ? '50%' : '100%',
          padding: '16px',
          borderRadius: '8px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#ffffff',
          height: '80px'
        }}
      >
        <Typography
          variant="h6"
          mb={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: '#000'
          }}
        >
          {translate('Group Title')}
          <IconButton onClick={() => setIsEditing(true)} size="small" aria-label="edit">
            <IconContainer icon={editsIcon} width={20} height={20} />
          </IconButton>
        </Typography>
        <Box sx={{marginTop: '8px'}}>
          <Typography
            variant="h5"
            mb={1}
            sx={{
              textAlign: 'start',
              color: '#000',
              fontWeight: 'bold'
            }}
          >
            {data?.group_title || ''}
          </Typography>
        </Box>
      </Box>

      <Modal
        open={isEditing}
        onClose={() => {
          setIsEditing(false);
          resetData();
        }}
        title={translate('Edit Group Title')}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        buttons={[
          {
            name: translate('Cancel'),
            onClick: () => {
              setIsEditing(false);
              resetData();
            },
            buttonBgColor: '#ffffff',
            buttonTextColor: 'black',
            buttonHoverColor: '#f5f5f5',
            type: 'outlined'
          },
          {
            name: translate('Save'),
            onClick: handleSubmitClick,
            buttonBgColor: '#d32f2f',
            buttonTextColor: '#ffffff',
            buttonHoverColor: '#b71c1c',
            loading: buttonLoader,
            disabled: buttonLoader || !group_title.trim()
          }
        ]}
      >
        <FormControl fullWidth sx={{marginTop: '12px'}}>
          <AdminTextInput bottomLabel={translate('Group Title')} placeholder={translate('Enter Group Title')} labelVariant="body2" value={group_title} onChange={e => setTitle(e.target.value)} isRequired />
        </FormControl>
      </Modal>
    </>
  );
};

export default GroupTitle;
