import React, {useEffect, useState} from 'react';
import {Box, List, ListItem, ListItemText, ListItemIcon, Typography, useMediaQuery, Divider, Alert, IconButton, Grid} from '@mui/material';
import AdminStructure from '../admin/common/AdminStructure';
import {addOrganization, deleteOrganization, getDashboardData, getOrganizationsApi, inviteUser} from '../../api';
import NoDataFoundMessage from '../../components/common/NoDataFoundMessage';
import Loader from '../../components/common/Loader';
import useTranslations from '../../hooks/useTranslation';
import {doubleRoundPersonIcon, multiPersonIcon, singleRoundPersonIcon} from '../../utils/svg';
import IconContainer from '../../components/common/IconContainer';
import Modal from '../../components/common/Modal';
import ItemList from '../admin/common/ItemList';
import AddOrganization from './AddOrganisation';
import {isEmptyObjectValues, validateEmail, validatePhone} from '../../utils';
import {CONSTANTS} from '../../constants';
import toast from 'react-hot-toast';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import AdminTextInput from '../admin/components/AdminTextInput';
function Organisations() {
  const {translate} = useTranslations();
  const [orgs, setOrgs] = useState([]);
  const [searchOrgs, setSearchOrgs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
  const [isAddOrganizationOpen, setIsAddOrganizationOpen] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [isDeleteOrganization, setIsDeleteOrganization] = useState(false);
  const [isAddAdmin, setIsAddAdmin] = useState(false);
  const [formData, setFormData] = useState({name: '', phone: '', email: ''});
  const [organizations, setOrganizations] = useState({
    name: '',
    contact_email: '',
    country: '',
    contact_name: ''
  });
  const [buttonLoader, setButtonLoader] = useState(false);

  const getDashboardDataHandler = async orgId => {
    try {
      const response = await getDashboardData(orgId);
      if (response?.data?.status === 200) {
        setDashboardData(response?.data?.data);
      }
    } catch (err) {}
  };

  const fetchOrgs = async () => {
    try {
      setLoading(true);
      const response = await getOrganizationsApi('');
      if (response?.data?.status === 200) {
        const organizations = response?.data?.data;
        setOrgs(organizations);
        setSearchOrgs(organizations);
        if (organizations.length > 0) {
          const firstOrg = organizations[0];
          setSelectedOrganization(firstOrg);
          getDashboardDataHandler(firstOrg.id);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleOrganizationClick = org => {
    setSelectedOrganization(org);
    getDashboardDataHandler(org.id);
  };

  const handleSubmit = async () => {
    if (isEmptyObjectValues(organizations)) {
      toast.error(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED);
      return;
    }
    setButtonLoader(true);
    const reqData = {
      name: organizations.name,
      contact_email: organizations.contact_email,
      country: organizations.country,
      contact_first_name: organizations.contact_name.split(' ')[0] || '',
      contact_last_name: organizations.contact_name.split(' ')[1] || ''
    };
    try {
      const response = await addOrganization(reqData);
      if (response.data.status === 200) {
        setIsAddOrganizationOpen(false);
        setOrganizations({
          name: '',
          contact_email: '',
          country: '',
          contact_name: ''
        });

        fetchOrgs();
      }
      setButtonLoader(false);
    } catch (error) {
      setButtonLoader(false);
    }
  };

  const handleDeleteOrganization = async () => {
    setButtonLoader(true);
    try {
      const response = await deleteOrganization(selectedOrganization.id);
      if (response.data.status === 200) {
        setIsDeleteOrganization(false);
        fetchOrgs();
      }
      setButtonLoader(false);
    } catch (error) {
      setButtonLoader(false);
    }
  };

  const handleInviteAdmin = async () => {
    setButtonLoader(true);
    try {
      const data = {
        first_name: formData?.name?.split(' ')[0] || '',
        last_name: formData?.name?.split(' ')[1] || '',
        mobile_number: formData?.phone,
        email: formData?.email,
        organization_id: [selectedOrganization.id],
        is_admin: true
      };
      const res = await inviteUser(data);
      if (res.data.status === 200) {
        setFormData({name: '', phone: '', email: ''});
        setIsAddAdmin(false);
        fetchOrgs();
      }
      setButtonLoader(false);
    } catch (error) {
      setButtonLoader(false);
    }
  };

  const searchHandler = value => {
    if (value.length === 0) {
      setOrgs(searchOrgs);
    } else {
      const filteredOrgs = searchOrgs.filter(org => org?.name?.toLowerCase().includes(value.toLowerCase()));
      setOrgs(filteredOrgs);
    }
  };

  const handleChange = e => {
    const {name, value} = e.target;
    if (name === 'phone') {
      if (!validatePhone(value)) {
        return;
      }
    }
    setFormData(prev => ({...prev, [name]: value}));
  };

  const handleMenuClose = e => {
    if (e) e.stopPropagation();
    setIsAddOrganizationOpen(false);
    setOrganizations({
      name: '',
      contact_email: '',
      country: '',
      contact_name: ''
    });
  };

  useEffect(() => {
    fetchOrgs();
  }, []);

  return (
    <div
      style={{
        backgroundImage: 'none !important',
        backgroundColor: '#f9f9f9',
        height: '100vh',
        width: '100vw',
        overflowY: isSmallScreen ? 'auto' : 'hidden'
      }}
    >
      <AdminStructure
        isSearch={true}
        searchHandler={searchHandler}
        heading={translate('Organizations')}
        handleAddClick={() => {
          setIsAddOrganizationOpen(true);
        }}
        isAddingOrganisation={true}
      >
        {loading ? (
          <Loader color="#9e0001" />
        ) : orgs?.length === 0 ? (
          <NoDataFoundMessage mt={5} message={translate('No data available for this organization')} />
        ) : (
          <Box
            display={'flex'}
            px={2}
            gap={2}
            sx={{
              height: {
                md: '85vh',
                xs: 'auto',
                sm: 'auto'
              },
              flexDirection: {
                md: 'row',
                sm: 'column',
                xs: 'column'
              },
              justifyContent: {
                md: 'space-between'
              }
            }}
          >
            {/*Organization List */}
            <Box width="80%" display={'flex'} flexDirection={'column'} gap={2}>
              <ItemList items={orgs.map(org => org)} handleClick={handleOrganizationClick} selectedItem={selectedOrganization} title={`${translate('Organization Account')} (${orgs.length})`} height={'100%'} />
            </Box>

            {/* Quick Look */}
            <Box
              width="68%"
              display="flex"
              flexDirection="column"
              gap={2}
              sx={{
                justifyContent: {
                  md: 'space-between'
                }
              }}
            >
              {dashboardData && (
                <Box bgcolor="#FFF" borderRadius="12px" padding={2} minHeight="250px" maxHeight={'350px'} width={'94%'}>
                  <Typography variant="h6" gutterBottom color="#d32f2f" fontWeight="800" textAlign="left" px={2} pt={1}>
                    {translate('Quick Look')}
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <IconContainer color="#d32f2f" icon={multiPersonIcon} width={50} height={50} marginRight={8} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        <Typography color="black" fontWeight={'bold'} variant={'h5'}>
                          {dashboardData.quickLookData['active_groups']}&nbsp;
                          {translate('Active Groups')}
                        </Typography>
                        <Typography color="#d32f2f" variant="body1">
                          {dashboardData.quickLookData['lifetime_groups']}&nbsp;
                          {translate('Lifetime Groups')} &bull;&nbsp;
                          {dashboardData.quickLookData['completed_groups']}&nbsp;
                          {translate('Finished Groups')}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <IconContainer color="#d32f2f" icon={singleRoundPersonIcon} width={50} height={50} marginRight={8} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        <Typography color="black" fontWeight={'bold'} variant={'h5'}>
                          {dashboardData.quickLookData['active_disciples']}&nbsp;
                          {translate('Disciples in Process')}
                        </Typography>
                        <Typography color="#d32f2f" variant="body1">
                          {dashboardData.quickLookData['lifetime_disciples']}&nbsp;
                          {translate('Lifetime Disciples')} &bull;&nbsp;
                          {dashboardData.quickLookData['completed_disciples']}&nbsp;
                          {translate('Disciples Finished')}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <IconContainer color="#d32f2f" icon={doubleRoundPersonIcon} width={50} height={50} marginRight={8} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        <Typography color="black" fontWeight={'bold'} variant={'h5'}>
                          {dashboardData.quickLookData['active_leaders']}&nbsp;
                          {translate('Active Leaders')}
                        </Typography>
                        <Typography color="#d32f2f" variant="body1">
                          {dashboardData.quickLookData['lifetime_leaders']}&nbsp;
                          {translate('Lifetime Leaders')} &bull;&nbsp;
                          {dashboardData.quickLookData['inactive_leaders']}&nbsp;
                          {translate('Inactive Leaders')}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Box>
              )}
              {/* Organisation Address details */}
              {selectedOrganization && (
                <Box bgcolor="#fff" borderRadius={3} p={3} height={'40%'} width="90%">
                  <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                    <Typography variant="h6" gutterBottom fontWeight="800" textAlign="left" color="#d32f2f" mb={1}>
                      {selectedOrganization.name}
                    </Typography>
                    <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                      <IconButton onClick={() => setIsAddAdmin(true)}>
                        <AddIcon style={{color: '#d32f2f'}} />
                      </IconButton>
                      <IconButton onClick={() => setIsDeleteOrganization(true)}>
                        <DeleteIcon style={{color: '#d32f2f'}} />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start'
                    }}
                  >
                    <Typography color="black" textAlign="left" variant="body1">
                      <strong>{translate('Location')}</strong>
                    </Typography>
                    <Typography color="black" textAlign="right" variant="body1">
                      {selectedOrganization.country || translate('N/A')}
                    </Typography>
                  </Box>
                  <Divider
                    sx={{
                      mb: 1,
                      borderColor: 'black',
                      borderWidth: '1px'
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    {/* First line */}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start'
                      }}
                    >
                      <Typography color="black" variant="body1" gutterBottom>
                        <strong>{translate('Active Membership')}</strong>
                      </Typography>
                      <Typography color="black" textAlign="right" variant="body1">
                        {/* 3000 */}
                        {selectedOrganization.active_membership || translate('N/A')}
                      </Typography>
                    </Box>

                    {/* Second line */}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start'
                      }}
                    >
                      <Typography color="black" variant="body1" gutterBottom>
                        <strong>{translate('Denomination')}</strong>
                      </Typography>
                      <Typography color="black" textAlign="right" variant="body1">
                        {/* Christian Church */}
                        {selectedOrganization.denomination || translate('N/A')}
                      </Typography>
                    </Box>
                  </Box>

                  <Divider
                    sx={{
                      mb: 1,
                      borderColor: 'black',
                      borderWidth: '1px'
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start'
                      }}
                    >
                      <Typography color="black" variant="body1" gutterBottom>
                        <strong>{translate('Contact')}</strong>
                      </Typography>
                      <Typography color="black" textAlign="right" variant="body1">
                        {`${selectedOrganization.contact_user_first_name || ''} ${selectedOrganization.contact_user_last_name || ''}`.trim('') || translate('N/A')}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start'
                      }}
                    >
                      <Typography color="black" variant="body1" gutterBottom>
                        <strong>{translate('Email')}</strong>
                      </Typography>
                      <Typography color="black" textAlign="right" variant="body1">
                        {selectedOrganization.contact_user_email || translate('N/A')}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start'
                      }}
                    >
                      <Typography color="black" variant="body2" gutterBottom>
                        <strong>{translate('Phone')}</strong>
                      </Typography>
                      <Typography color="black" textAlign="right" variant="body1">
                        {selectedOrganization.contact_user_mobile || translate('N/A')}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </AdminStructure>

      {/* modal for delete organization */}
      <Modal
        open={isDeleteOrganization}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        title={translate('Delete Organization')}
        onClose={() => setIsDeleteOrganization(false)}
        buttons={[
          {name: 'Cancel', onClick: () => setIsDeleteOrganization(false), buttonBgColor: '#ffffff', buttonTextColor: 'black', buttonHoverColor: '#f5f5f5', type: 'outlined'},
          {
            name: 'Delete',
            buttonBgColor: '#d32f2f',
            buttonTextColor: '#ffffff',
            buttonHoverColor: '#b71c1c',
            onClick: () => handleDeleteOrganization(),
            loading: buttonLoader
          }
        ]}
      >
        <p>{translate("All the user for this organization will be deleted with all organization's data")}</p>
      </Modal>

      <Modal
        open={isAddAdmin}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        title={translate('Add Organization Admin')}
        onClose={() => {
          setIsAddAdmin(false);
          setFormData({name: '', phone: '', email: ''});
        }}
        buttons={[
          {
            name: 'Cancel',
            onClick: () => {
              setIsAddAdmin(false);
              setFormData({name: '', phone: '', email: ''});
            },
            buttonBgColor: '#ffffff',
            buttonTextColor: 'black',
            buttonHoverColor: '#f5f5f5',
            type: 'outlined'
          },
          {
            name: 'Invite',
            buttonBgColor: '#d32f2f',
            buttonTextColor: '#ffffff',
            buttonHoverColor: '#b71c1c',
            disabled: !formData.name || !validateEmail(formData.email),
            onClick: () => handleInviteAdmin(),
            loading: buttonLoader
          }
        ]}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AdminTextInput fullWidth name="name" bottomLabel={translate('Administrator Name')} placeholder={translate('Enter Administrator Name')} value={formData.name} onChange={handleChange} variant="outlined" labelVariant="body2" isRequired />
          </Grid>
          <Grid item xs={12}>
            <AdminTextInput type="text" fullWidth name="phone" bottomLabel={translate('Phone')} placeholder={translate('Enter Phone Number')} value={formData.phone} onChange={handleChange} variant="outlined" labelVariant="body2" />
          </Grid>
          <Grid item xs={12}>
            <AdminTextInput fullWidth name="email" bottomLabel={translate('Email')} placeholder={translate('Enter Email')} value={formData.email} onChange={handleChange} variant="outlined" labelVariant="body2" isRequired />
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={isAddOrganizationOpen}
        onClose={handleMenuClose}
        title={translate('Add Organization')}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        buttons={[
          {name: 'Cancel', onClick: () => handleMenuClose(), buttonBgColor: '#ffffff', buttonTextColor: 'black', buttonHoverColor: '#f5f5f5', type: 'outlined'},
          {
            name: 'Save',
            buttonBgColor: '#d32f2f',
            buttonTextColor: '#ffffff',
            buttonHoverColor: '#b71c1c',
            onClick: () => handleSubmit(),
            disabled: isEmptyObjectValues(organizations),
            loading: buttonLoader
          }
        ]}
      >
        <AddOrganization setFormData={setOrganizations} formData={organizations} />
      </Modal>
    </div>
  );
}

export default Organisations;
