import React from 'react';
import {Avatar, Box, Button, IconButton, Typography, Tooltip, Paper, useTheme, CircularProgress} from '@mui/material';
import UserContainer from '../components/common/UserContainer';
import {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {FILE_PATH_URL} from '../constants';
import {deleteDiscipleApi, getGroupApi, getGroupDiscipleApi, getMeetings} from '../api';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import moment from 'moment';
import TextInput from '../components/common/TextInput';
import theme from '../styles/theme';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '../components/common/Modal';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {sendInviteToDisciple} from '../api';
import useLeaderStore from '../store/leaderStore';
import {FILE_PATH_PROFILE_URL} from '../constants';
import LaunchIcon from '@mui/icons-material/Launch';
import Loader from '../components/common/Loader';
import useTranslations from '../hooks/useTranslation';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ManuallyInvitedModal from '../components/common/ManuallyInvitedModal';
import SpeakerNotesOffOutlinedIcon from '@mui/icons-material/SpeakerNotesOffOutlined';
import IconContainer from '../components/common/IconContainer';
import {attendanceIcon, readingIcon} from '../utils/svg';

const DiscipleItem = ({disciple, onClick, deleteHandler, setInvitedDisciple}) => {
  const {translate} = useTranslations();
  const [resendModalOpen, setResendModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const groupID = useParams().id;

  const sendInviteHandler = async () => {
    setLoading(true);
    let emails = [];
    if (disciple?.email) {
      emails.push(disciple?.email);
    }
    const data = {
      emails: [disciple?.email],
      group_id: groupID
    };
    try {
      const resp = await sendInviteToDisciple(data);
      if (resp?.data.status === 200) {
        setInvitedDisciple(prevInvitedDisciple => {
          return prevInvitedDisciple.map(d => (d.email === disciple.email ? {...d, invite_count: d.invite_count + 1} : d));
        });
      }
      setLoading(false);
      setResendModalOpen(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setResendModalOpen(false);
    setLoading(false); // Reset loading state when the modal closes
  };
  const isInvited = disciple?.user_status === 'INVITED';
  const isHide = !(disciple?.status === 'ARCHIVED' || disciple?.status === 'COMPLETED');

  return (
    <>
      <Modal
        open={resendModalOpen}
        setOpen={handleModalClose}
        title={translate('Resend Invite')}
        buttons={[
          {name: translate('No'), onClick: () => handleModalClose()},
          {
            name: translate('Yes'),
            onClick: () => sendInviteHandler(),
            loading: loading,
            disabled: loading
          }
        ]}
      >
        <Typography sx={{color: 'white'}}>{translate('Are you sure you want to resend invite to disciple?')}</Typography>
      </Modal>
      <Box p={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{width: '100%'}}>
          <Box onClick={() => (isHide ? onClick() : () => {})} display="flex" alignItems="center" sx={{cursor: 'pointer'}}>
            <Avatar sx={{width: 56, height: 56}} alt={String(disciple?.first_name).toUpperCase()} src={disciple?.avatar ? `${FILE_PATH_PROFILE_URL}/${disciple?.avatar}` : ''} />
            <Box textAlign="left" ml={2}>
              <Typography variant="h5" fontWeight={600}>
                {disciple?.first_name || '' + disciple?.last_name || ''}
              </Typography>
              {disciple?.title && (
                <Typography variant="p" color="white" fontSize={14}>
                  {disciple?.title?.replace(/,/g, ' • ')}
                </Typography>
              )}
              {isInvited && (
                <Typography variant="p" color="white" fontSize={14}>
                  {translate('Invite Count: ')}
                  {disciple?.invite_count || 1}
                </Typography>
              )}
            </Box>
          </Box>
          <Box display="flex" alignItems="end">
            {disciple?.is_manually_activated == 1 && !isInvited && (
              <IconButton onClick={() => setIsModalOpen(true)} style={{cursor: 'pointer'}}>
                <SpeakerNotesOffOutlinedIcon style={{color: 'white', fontSize: '2rem'}} />
              </IconButton>
            )}
            {isInvited && (
              <Tooltip title={translate('Resend Invite')} placement="top">
                <IconButton
                  onClick={() => {
                    setResendModalOpen(true);
                  }}
                  disabled={loading}
                >
                  <AutorenewIcon
                    fontSize="22"
                    style={{
                      fontSize: '2rem',
                      color: theme.palette.error.main
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
            {/* {isHide && (
              <Tooltip title={translate('Delete')} placement="top">
                <IconButton
                  onClick={() => {
                    deleteHandler(disciple?.id);
                  }}
                >
                  <DeleteIcon
                    fontSize="22"
                    style={{
                      fontSize: '2rem',
                      color: theme.palette.error.main
                    }}
                  />
                </IconButton>
              </Tooltip>
            )} */}
          </Box>
        </Box>
      </Box>
      <ManuallyInvitedModal bgColor="#fff" textColor="#000" titleColor="#000" setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
    </>
  );
};

const Home = () => {
  const {translate} = useTranslations();
  const theme = useTheme();
  const [groupData, setGroupData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [prevloader, setPrevLoader] = useState(false);
  const [nextloader, setNextLoader] = useState(false);
  const [sortedDisciples, setSortedDisciples] = useState([]);
  const [sortedActiveDisciples, setSortedActiveDisciples] = useState([]);
  const [sortedInvitedDisciples, setSortedInvitedDisciples] = useState([]);
  const [sortedOrder, setSortedOrder] = useState('desc');
  const [activeDisciple, setActiveDisciple] = useState([]);
  const [invitedDisciple, setInvitedDisciple] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingDiscipleId, setDeletingDiscipleId] = useState(null);
  const [meetId, setMeetId] = useState(null);
  const [bibleData, setBibleData] = useState([]);
  const [weeklyReadingLoader, setWeeklyReadingLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [sequenceNumber, setSequenceNumber] = useState();
  const [totalWeekNumber, setTotalWeekNumber] = useState(0);

  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => setOpen(false);

  const {setMeetings, setGroupsData, meetings, setGroupDisciples} = useLeaderStore(state => state);
  const params = useParams();
  const navigate = useNavigate();

  const handlePrevious = async () => {
    const newSequence = sequenceNumber - 3;
    setPrevLoader(true);
    await groupMeetingsById(newSequence);
    setPrevLoader(false);
  };

  const handleNext = async () => {
    const newSequence = sequenceNumber + 1;
    setNextLoader(true);
    await groupMeetingsById(newSequence);
    setNextLoader(false);
  };

  function disablePrevWeek() {
    if (Number(sequenceNumber) <= 3) {
      return true;
    }
  }

  function disableNextWeek() {
    if (Number(sequenceNumber) >= Number(totalWeekNumber) - 1) {
      return true;
    }
  }

  const getGroupDetailsHandler = async () => {
    try {
      const res = await getGroupDiscipleApi(params?.id);
      if (res?.data?.status === 200) {
        setSortedDisciples(res?.data?.data);
      }
    } catch (error) {}
  };

  const getGroupByIdHandler = async () => {
    try {
      const res = await getGroupApi(params.id);
      if (res?.data?.status === 200) {
        setGroupData(res?.data?.data);
        setGroupsData(res?.data?.data);
      }
    } catch (error) {}
  };

  const groupMeetingsById = async (requested_sequence = sequenceNumber) => {
    try {
      setWeeklyReadingLoader(true);
      const res = await getMeetings(params.id, '', requested_sequence);
      if (res?.data?.status === 200) {
        setMeetings(res?.data?.data);
        setBibleData(res?.data?.data);
      } else {
        navigate('/leader');
        setMeetings([]);
      }
      setWeeklyReadingLoader(false);
    } catch (error) {
      setWeeklyReadingLoader(false);
    }
  };

  const handleSearchTextInput = e => {
    setActiveDisciple(sortedActiveDisciples?.filter(org => org?.first_name?.toLowerCase().includes(e.target.value.toLowerCase()) || org?.last_name?.toLowerCase()?.includes(e.target.value.toLowerCase())));
    setInvitedDisciple(sortedInvitedDisciples?.filter(org => org?.first_name?.toLowerCase().includes(e.target.value.toLowerCase()) || org?.last_name?.toLowerCase()?.includes(e.target.value.toLowerCase())));
  };

  const handleSort = () => {
    setSortedOrder(prevSortOrder => (prevSortOrder == 'desc' ? 'asc' : 'desc'));
    setSortedDisciples(prevSortedOrgs => [...prevSortedOrgs].sort((a, b) => (sortedOrder == 'asc' ? a.first_name.localeCompare(b.first_name) : b.first_name.localeCompare(a.first_name))));
  };

  const deleteDiscipleHandler = async id => {
    try {
      const res = await deleteDiscipleApi(id);
      if (res?.data?.status === 200) {
        setDeleteModalOpen(false);
        getGroupDetailsHandler();
      }
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (meetings?.group_week_meetings) {
      const lastMeetingSequenceNumber = meetings?.group_week_meetings?.[meetings.group_week_meetings.length - 1]?.sequence_number || '';
      setSequenceNumber(lastMeetingSequenceNumber);
    }
    if (meetings) {
      const totalweekNumber = (meetings?.meeting_weeks || 0) + (meetings?.total_skip_weeks || 0);
      setTotalWeekNumber(totalweekNumber);
    }
  }, [meetings]);

  useEffect(() => {
    const getGroupDetails = getGroupDetailsHandler();
    const getGroupById = getGroupByIdHandler();
    const getMeetingById = groupMeetingsById();
    setLoading(true);
    Promise.all([getGroupDetails, getGroupById, getMeetingById])
      .then(results => {
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const invitedDis = sortedDisciples?.filter(dis => dis?.user_status === 'INVITED');

    const activeDis = sortedDisciples?.filter(dis => dis?.user_status === 'ACTIVE');

    setInvitedDisciple(invitedDis);
    setActiveDisciple(activeDis);
    setSortedActiveDisciples(activeDis);
    setSortedInvitedDisciples(invitedDis);
    setGroupDisciples(sortedDisciples);
  }, [sortedDisciples]);

  const displayText = moment(groupData?.group_meeting?.starting_time).format('DD-MM-YYYY') > moment(new Date(Date.now())).format('DD-MM-YYYY') ? translate('Reading') : groupData?.outside_book_reading_type ? translate('Reading') : translate('Meeting');

  return (
    <>
      <Modal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        title={translate('Delete Disciple')}
        buttons={[
          {name: translate('Close'), onClick: () => setDeleteModalOpen(false)},
          {
            name: translate('Delete'),
            onClick: () => deleteDiscipleHandler(deletingDiscipleId)
          }
        ]}
      >
        <Typography sx={{color: 'white'}}>{translate('Are you sure you want to delete disciple?')}</Typography>
      </Modal>

      <UserContainer
        addText={translate('Disciple')}
        addText2={displayText}
        headingText={groupData?.name ? `${groupData?.name}${translate("'s Group")}` : ''}
        filePath={groupData?.avatar ? `${FILE_PATH_URL}/${groupData?.avatar}` : ''}
        isShow={meetings && groupData?.status !== 'COMPLETED' ? true : false}
        isShow2={groupData?.status !== 'COMPLETED'}
        isGroup={true}
        groupData={{
          ...groupData,
          group_meeting: meetings,
          disciple: sortedDisciples
        }}
        headerText={groupData?.organization || ''}
      >
        <Box display="flex" flexDirection="column" justifyContent="space-between" pt={1}>
          {loading ? (
            <Loader />
          ) : sortedDisciples && sortedDisciples?.length > 0 ? (
            <Box>
              <Box px={2} pb={1} borderBottom={2} borderColor="black">
                <Button sx={{fontWeight: 400}} onClick={() => navigate(`/group/send-email/${groupData?.name}/${groupData?.id}`)} variant="contained" color="secondary">
                  {translate('Send Email To Group')}
                </Button>
              </Box>
              {sortedDisciples.length > 2 && (
                <Box display="flex" alignItems="center" gap={2} pt={2} px={2}>
                  <TextInput type="text" placeholder={translate('Search...')} onChange={handleSearchTextInput} />
                  <IconButton onClick={handleSort} sx={{marginBottom: '16px'}}>
                    <SortByAlphaIcon
                      fontSize="22"
                      style={{
                        fontSize: '2rem',
                        color: theme.palette.error.main
                      }}
                    />
                  </IconButton>
                </Box>
              )}
              <Typography textAlign="left" m={2} variant="h5" fontWeight={600}>
                {translate('Active Disciples')} ({activeDisciple?.length})
              </Typography>
              {activeDisciple?.map((dis, i) => (
                <DiscipleItem
                  key={i}
                  disciple={dis}
                  onClick={() => navigate(`/assessment/${groupData?.level}/${groupData?.id}/${dis?.user_id}`)}
                  deleteHandler={() => {
                    setDeleteModalOpen(true);
                    setDeletingDiscipleId(dis?.disciple_id);
                  }}
                />
              ))}

              <Typography textAlign="left" m={2} variant="h5" fontWeight={600}>
                {translate('Invited Disciples')} ({invitedDisciple?.length})
              </Typography>
              {invitedDisciple?.map((dis, i) => (
                <>
                  <DiscipleItem
                    key={i}
                    disciple={dis}
                    onClick={() => {}}
                    deleteHandler={() => {
                      setDeleteModalOpen(true);
                      setDeletingDiscipleId(dis?.disciple_id);
                    }}
                    setInvitedDisciple={setInvitedDisciple}
                  />
                </>
              ))}
            </Box>
          ) : (
            <Box px={2} my={10}>
              <Typography variant="h5" marginBottom={4}>
                {translate('Invite your first people!')}
              </Typography>
              <Typography variant="p">{translate('Once a disciple is added manually or accepts an invitation, they will be added here')}</Typography>
            </Box>
          )}

          {groupData?.status !== 'COMPLETED' && (
            <Box my={3}>
              {meetings?.group_week_meetings?.map(data => {
                const meetingDate = moment(data?.meeting_date).startOf('day'); // Convert meeting date to start of day for comparison

                return (
                  <Box key={data.id}>
                    {/* Ensure you have a unique key for each child in a list */}
                    <Typography variant="h5" fontWeight="bold" fontSize="large" display="flex" justifyContent="center" alignItems="center">
                      {translate('Reading for ')}
                      {meetingDate.format('MMMM DD')} {translate('Meeting')} {/* <IconButton> */}
                      <IconButton>
                        <IconContainer
                          icon={attendanceIcon}
                          onClick={e => {
                            e.stopPropagation();
                            navigate(`/group/attendance/${groupData?.name}/${groupData?.id}/${data?.id}`);
                          }}
                          width={20}
                          height={20}
                          color={'white'}
                          marginRight="10px"
                        />
                      </IconButton>
                      <IconButton>
                        <IconContainer
                          icon={readingIcon}
                          onClick={e => {
                            e.stopPropagation();
                            handleOpenModal();
                            setMeetId(data?.id);
                          }}
                          width={20}
                          height={20}
                          color={'white'}
                          marginRight="10px"
                        />
                      </IconButton>
                    </Typography>
                    <Typography>{data?.week_chapters}</Typography>
                    {data?.outside_reading?.current_book?.split('|')?.map(book => {
                      return (
                        <Box mt={1} mx={2} display="flex" alignItems="center" justifyContent="center">
                          <Typography marginRight={1}>{book.split(';')[0]}</Typography>
                        </Box>
                      );
                    })}
                  </Box>
                );
              })}
              {!loading && meetings?.group_week_meetings?.length > 0 && (
                <Box my={2} display="flex" gap={2} justifyContent="center">
                  <IconButton
                    onClick={handlePrevious}
                    style={{
                      fontSize: '14px',
                      color: disablePrevWeek() ? 'lightgray' : 'white'
                    }}
                    disabled={prevloader || disablePrevWeek()}
                  >
                    {prevloader ? (
                      <CircularProgress size={24} sx={{color: 'white'}} />
                    ) : (
                      <>
                        <ArrowBackIosIcon />
                        {translate('Previous Week')}
                      </>
                    )}
                  </IconButton>

                  <IconButton
                    onClick={handleNext}
                    style={{
                      fontSize: '14px',
                      color: disableNextWeek() ? 'lightgray' : 'white'
                    }}
                    disabled={nextloader || disableNextWeek()}
                  >
                    {nextloader ? (
                      <CircularProgress size={24} sx={{color: 'white'}} />
                    ) : (
                      <>
                        {translate('Next Week')}
                        <ArrowForwardIosIcon />
                      </>
                    )}
                  </IconButton>
                </Box>
              )}

              {/* Modal to display Bible reading data */}
              <Modal open={open} setOpen={setOpen} modalbgColor={'white'} buttons={[{name: translate('Close'), onClick: handleCloseModal}]}>
                <Box
                  sx={{
                    backgroundColor: 'white',
                    color: '#000000',
                    borderRadius: 2
                  }}
                >
                  <Box color="#000000" minHeight="275px">
                    {weeklyReadingLoader ? (
                      <Loader color="#ca0000" />
                    ) : (
                      bibleData?.group_week_meetings
                        ?.filter(item => item.id === meetId)
                        .map((weekData, weekIndex) => (
                          <React.Fragment key={weekIndex}>
                            <Typography variant="h5" color="#000000" padding="24px 0" fontWeight={'bold'} textAlign={'left'}>
                              {translate('Reading Plan for Week')} {moment(weekData.meeting_date).format('dddd, MMMM Do YYYY')}
                            </Typography>
                            {weekData.week_reading && weekData.week_reading.length === 0 ? (
                              <Box minHeight="275px" display="flex" alignItems="center" justifyContent="center">
                                <Typography fontSize={'16px'} color="#000000">
                                  {translate('No Bible Reading for this week.')}
                                </Typography>
                              </Box>
                            ) : (
                              weekData.week_reading?.map((subItem, subIndex) => (
                                <Paper elevation={0} key={subIndex}>
                                  <Box
                                    gap={1}
                                    color="#000000"
                                    mt={1}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'flex-start',
                                      flexDirection: 'column'
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignContent: 'left',
                                        textAlign: 'left'
                                      }}
                                    >
                                      <label style={{alignContent: 'center'}} htmlFor={`week-${weekIndex}-${subIndex}`}>
                                        {moment(subItem.date, 'MMMM Do YYYY').format('dddd, MMMM Do')} &bull; {subItem.chapters}
                                      </label>
                                    </Box>
                                  </Box>
                                </Paper>
                              ))
                            )}
                          </React.Fragment>
                        ))
                    )}
                  </Box>
                </Box>
              </Modal>

              {meetings?.next_book && (
                <Box>
                  <Typography variant="h5" fontWeight="bold" fontSize="large" pt={1}>
                    {translate("It's Time to Order!")}
                  </Typography>
                  {meetings?.next_book?.split('|')?.map(book => {      
                    return (
                      <Box mt={1} mx={2} display="flex" alignItems="center" justifyContent="center">
                        <Typography marginRight={1}>{book?.split(';')[0]}</Typography>
                        <Link to={book?.split(';')[1]} target="_blank">
                          <LaunchIcon sx={{color: 'white'}} />
                        </Link>
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </UserContainer>
    </>
  );
};

export default Home;
