import React, {useState, useEffect} from 'react';
import FormWrapper from '../../common/StyledFormWrapper';
import CssBaseline from '@mui/material/CssBaseline';
import {Box, Grid} from '@mui/material';
import Alert from '@mui/material/Alert';
import MultiLineTextInput from '../../common/MultiLineInput';
import {getAssessmentAnswers, getAssessmentsQuestions, postAssessmentsQuestions} from '../../../api';
import {CONSTANTS} from '../../../constants';
import QuestionHeadings from './QuestionHeadings';
import useTranslations from '../../../hooks/useTranslation';
import {useParams} from 'react-router-dom';

const PageFive = ({nextpage, user, page, totalPage}) => {
  const {translate} = useTranslations();
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {userId, level} = useParams();
  const placeHolderData = {
    20: translate('Do they need a ramp?'),
    21: translate('Have you had a blink for them?')
  };
  const fetchQuestions = async () => {
    try {
      setLoading(true);
      const response = await getAssessmentsQuestions(CONSTANTS.ASSESSMENT_QUESTIONS_IDS.ASSESSMENT_4, user?.id, user?.group);
      if (response?.data?.status === 200) {
        const translatedQuestions = response?.data?.data.map(question => ({
          ...question,
          question: translate(question.question) // Translate the question here
        }));
        setQuestions(translatedQuestions);
        // setQuestions(response?.data?.data);
        const initialAnswers = translatedQuestions.reduce((acc, curr) => {
          acc[curr.id] = curr?.answer || '';
          return acc;
        }, {});
        setAnswers(initialAnswers);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const previousAnswer = async () => {
    try {
      const response = await getAssessmentAnswers(userId, CONSTANTS.ASSESSMENT_QUESTIONS_IDS.ASSESSMENT_4);
      if (response.data.status === 200) {
        const responseData = response.data.data;
        const newAnswers = responseData.reduce((acc, item) => {
          acc[item.question_id] = item.answer || '';
          return acc;
        }, {});
        setAnswers(newAnswers);
      }
    } catch (error) {}
  };

  const handleAnswerChange = (questionId, value) => {
    setError(null);
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: value
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const isAllQuestionsAnswered = true;
    if (isAllQuestionsAnswered) {
      await submitAnswers();
    } else {
      setError(translate('All fields are required!'));
    }
  };

  const submitAnswers = async () => {
    const data = {
      user_id: user?.id,
      group_id: user?.group,
      questions: Object.entries(answers).map(([questionId, answer]) => ({
        question_id: questionId,
        answer: answer.trim()
      }))
    };
    try {
      setLoading(true);
      const response = await postAssessmentsQuestions(data);
      if (response?.data?.status === 200) {
        nextpage();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);
  return (
    <>
      <CssBaseline />
      <Box mt={1} px={3} overflow="auto">
        <FormWrapper
          buttonInputs={[
            {
              text: translate('Import Previous Year Assessment'),
              onClick: () => previousAnswer(),
              type: 'button',
              loading: loading,
              hide: level === 'D1'
            },
            {
              text: translate('Continue'),
              onClick: e => handleSubmit(e),
              type: 'submit'
            }
          ]}
          // headingText={`Assessment ${page} of ${totalPage}`}
          loading={loading}
        >
          <QuestionHeadings heading1={`${translate('ASSESSMENT')} ${page} ${translate('OF')} ${totalPage}`} heading2={translate('CUSTOMIZATIONS')} heading3={translate('UPDATE AS NEEDED')} />
          <Box my={0}>
            {questions?.map((ques, index) => (
              <Box key={index} sx={{textAlign: 'left'}}>
                <MultiLineTextInput label={ques.question} placeholder={placeHolderData[ques.id]} value={answers[ques.id] || ''} onChange={e => handleAnswerChange(ques.id, e.target.value)} autoComplete="off" margin="normal" multiline rows={2} />
              </Box>
            ))}
          </Box>
          <Grid item xs={12} sx={{height: '50px'}}>
            {error && <Alert severity="error">{error}</Alert>}
          </Grid>
        </FormWrapper>
      </Box>
    </>
  );
};

export default PageFive;
