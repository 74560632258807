import React, {useEffect, useState} from 'react';
import Alert from '@mui/material/Alert';
import {Box, CssBaseline, Typography, Grid} from '@mui/material';
import FormWrapper from '../../common/StyledFormWrapper';
import theme from '../../../styles/theme';
import {getAssessmentAnswers, getAssessmentsQuestions, postAssessmentsQuestions} from '../../../api';
import {CONSTANTS} from '../../../constants';
import MultiLineTextInput from '../../common/MultiLineInput';
import DatePicker from '../../common/DatePicker';
import QuestionHeadings from './QuestionHeadings';
import moment from 'moment';
import useTranslations from '../../../hooks/useTranslation';
import {useParams} from 'react-router-dom';

function dateQuestionComponent(ques, setQuestions, setError, setOpen, open) {
  const handleChangeInput = (value, id) => {
    setError(null);
    setQuestions(prevData =>
      prevData.map(item => {
        if (item.id === id) {
          return {...item, answer: {...item.answer, answer: value}};
        }
        return item;
      })
    );
  };
  return (
    <Box key={ques?.id} display="flex" alignItems="center" mb={2} justifyContent="space-between">
      <Typography variant="p" fontSize={10} sx={{width: '40%'}}>
        {ques?.question}
      </Typography>
      <DatePicker open={open[ques?.id]} setOpen={() => setOpen(prev => ({...prev, [ques?.id]: true}))} onClose={() => setOpen(prev => ({...prev, [ques?.id]: false}))} value={moment(ques?.answer?.answer) || ''} onChange={value => handleChangeInput(value, ques?.id)} disablePast />
    </Box>
  );
}

function questionComponent(ques, setQuestions, setError) {
  const handleChangeInput = (e, id) => {
    setError(null);
    setQuestions(prevData =>
      prevData.map(item => {
        if (item.id === id) {
          return {
            ...item,
            answer: {...item.answer, answer: e.target.value}
          };
        }
        return item;
      })
    );
  };

  return (
    <Box key={ques?.id} mb={2}>
      <MultiLineTextInput
        rows={2}
        InputProps={{
          theme: theme
        }}
        placeholder={ques?.question}
        onChange={e => handleChangeInput(e, ques?.id)}
        value={ques?.answer?.answer}
        multiline
        autoComplete="off"
      />
    </Box>
  );
}

const PageNine = ({nextpage, user, page, totalPage}) => {
  const {translate} = useTranslations();
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState({
    32: false,
    33: false
  });
  const {userId, level} = useParams();

  const submitAnswers = async () => {
    const questionsData = questions.map(item => {
      return {
        question_id: item.id,
        answer: item.answer.answer || ''
      };
    });
    const data = {
      user_id: user?.id,
      group_id: user?.group,
      questions: questionsData?.filter(item => item)
    };
    try {
      setLoading(true);
      const response = await postAssessmentsQuestions(data);
      if (response?.data?.status === 200) {
        nextpage();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const isAllQuestionsAnswered = true;
    if (isAllQuestionsAnswered) {
      await submitAnswers();
    } else {
      setError(translate('All fields are required!'));
    }
  };

  const previousAnswer = async () => {
    try {
      const response = await getAssessmentAnswers(userId, CONSTANTS.ASSESSMENT_QUESTIONS_IDS.ASSESSMENT_8);
      if (response.data.status === 200) {
        const responseData = response.data.data;
        setQuestions(prevQuestions =>
          prevQuestions.map(question => {
            const matchingAnswer = responseData.find(item => item.question_id === question.id);
            if (matchingAnswer) {
              return {
                ...question,
                answer: {
                  ...question.answer,
                  answer: matchingAnswer.answer || ''
                }
              };
            } else {
              return {
                ...question,
                answer: {
                  ...question.answer,
                  answer: ''
                }
              };
            }
          })
        );
      }
    } catch (error) {}
  };
  const fetchQuestions = async () => {
    try {
      setLoading(true);
      const response = await getAssessmentsQuestions(CONSTANTS.ASSESSMENT_QUESTIONS_IDS.ASSESSMENT_8, user?.id, user?.group);
      if (response?.data?.status === 200) {
        const initialQues = response?.data?.data?.map(ques => {
          return {
            ...ques,
            question: translate(ques.question), // Translate the question here
            answer: {
              answer: ques?.answer ? ques?.answer : ''
            }
          };
        });
        setQuestions(initialQues);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  return (
    <>
      <CssBaseline />
      <Box mt={1} px={3} overflow="auto">
        <FormWrapper
          buttonInputs={[
            {
              text: translate('Import Previous Year Assessment'),
              onClick: () => previousAnswer(),
              type: 'button',
              loading: loading,
              hide: level === 'D1'
            },
            {
              text: translate('Continue'),
              onClick: e => handleSubmit(e),
              type: 'submit'
            }
          ]}
          // headingText={`Assessment ${page} of ${totalPage}`}
          loading={loading}
        >
          <QuestionHeadings heading1={`${translate('ASSESSMENT')} ${page} ${translate('OF')} ${totalPage}`} heading2={translate('SERVING AND MISSIONS')} heading3={translate('BY END OF SIX MONTH')} />
          <Box my={1} textAlign="left">
            {questions?.map(ques => {
              if (ques.id === 32 || ques.id === 33) {
                return dateQuestionComponent(ques, setQuestions, setError, setOpen, open);
              }
              if (ques.id === 34 || ques.id === 35) {
                return questionComponent(ques, setQuestions, setError);
              }
            })}
          </Box>
          <Grid item xs={12} sx={{height: '50px'}}>
            {error && <Alert severity="error">{error}</Alert>}
          </Grid>
        </FormWrapper>
      </Box>
    </>
  );
};

export default PageNine;
