import React, {useState, useEffect} from 'react';
import FormWrapper from '../../common/StyledFormWrapper';
import CssBaseline from '@mui/material/CssBaseline';
import {Box, Grid, Typography} from '@mui/material';
import Alert from '@mui/material/Alert';
import {getAssessmentAnswers, getAssessmentsQuestions, postAssessmentsQuestions} from '../../../api';
import {CONSTANTS} from '../../../constants';
import CheckBoxInput from '../../common/CheckBoxInput';
import DropdownWithScore from '../../common/DropdownScore';
import QuestionHeadings from './QuestionHeadings';
import useTranslations from '../../../hooks/useTranslation';
import {useParams} from 'react-router-dom';

const PageThree = ({nextpage, user, page, totalPage}) => {
  const {translate} = useTranslations();
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [error, setError] = useState(null);
  const [stepAnswer, setStepAnswer] = useState({
    9: 0,
    10: 0
  });
  const {userId, level} = useParams();
  const doingAssessment = {
    DOING_ASSESSMENT: [
      {
        value: 'Nothing (Simply a Consumer, Getting a Paycheck)',
        label: translate('Nothing (Simply a Consumer, Getting a Paycheck)'),
        score: 0
      },
      {
        value: 'Consistently Applying God’s Truths (New and Old)',
        label: translate('Consistently Applying God’s Truths (New and Old)'),
        score: 2
      },
      {value: 'Eager to be Taught', label: translate('Eager to be Taught'), score: 3},
      {
        value: 'Volunteer (Parking, Greeting, Kids, Helping Outside of Job Description)',
        label: translate('Volunteer (Parking, Greeting, Kids, Helping Outside of Job Description)'),
        score: 6
      },
      {
        value: 'Learning to Learn (Following On Their Own, Self-Starter)',
        label: translate('Learning to Learn (Following On Their Own, Self-Starter)'),
        score: 7
      },
      {
        value: 'Learning to Lead (Leading Volunteers, Assistant Manager)',
        label: translate('Learning to Lead (Leading Volunteers, Assistant Manager)'),
        score: 10
      },
      {
        value: 'Leading Leaders (Pastor or Senior Leader, Executive)',
        label: translate('Leading Leaders (Pastor or Senior Leader, Executive)'),
        score: 11
      },
      {
        value: 'Influencing/Teaching (Small Groups, Visionary, Management)',
        label: translate('Influencing/Teaching (Small Groups, Visionary, Management)'),
        score: 14
      },
      {
        value: 'Reaching the World (Missionary, Blogging/Speaking)',
        label: translate('Reaching the World (Missionary, Blogging/Speaking)'),
        score: 14
      }
    ]
  };

  const beAssessment = {
    BE_ASSESSMENT: [
      {
        value: 'Not Hearing from God or Bible',
        label: translate('Not Hearing from God or Bible'),
        score: 0
      },
      {
        value: 'Hearing, Seeing and Not Doing (Guilty? Idle?)',
        label: translate('Hearing, Seeing and Not Doing (Guilty? Idle?)'),
        score: 1
      },
      {
        value: 'Hearing, Applying, and Getting It',
        label: translate('Hearing, Applying, and Getting It'),
        score: 4
      },
      {
        value: 'Asking to Serve, Compelled to Join God in His Work',
        label: translate('Asking to Serve, Compelled to Join God in His Work'),
        score: 5
      },
      {
        value: 'They know what to do and why to do it',
        label: translate('They know what to do and why to do it'),
        score: 8
      },
      {
        value: 'They are following on their own (you don’t have to push much anymore)',
        label: translate('They are following on their own (you don’t have to push much anymore)'),
        score: 9
      },
      {
        value: 'They are all in on following God and serving God',
        label: translate('They are all in on following God and serving God'),
        score: 9
      },
      {
        value: 'They are a servant not just serving (always available for God’s greater plan.)',
        label: translate('They are a servant not just serving (always available for God’s greater plan.)'),
        score: 12
      },
      {
        value: 'They are focused on championing God’s kingdom. It isn’t about them or theirs.',
        label: translate('They are focused on championing God’s kingdom. It isn’t about them or theirs.'),
        score: 14
      }
    ]
  };

  const answersData = {
    9: translate('Eg. Nursery, teaching, etc'),
    10: translate("Eg. They say they're called to Saudi Arabia"),
    11: [
      {label: translate('Step Back Doing'), value: 'Step Back Doing'},
      {label: translate('Step Up Doing'), value: 'Step Up Doing'}
    ]
  };

  const previousAnswer = async () => {
    try {
      const response = await getAssessmentAnswers(userId, CONSTANTS.ASSESSMENT_QUESTIONS_IDS.ASSESSMENT_2);
      if (response.data.status === 200) {
        const responseData = response.data.data;
        responseData.forEach(item => {
          if (item.answer.includes('|')) {
            const newAnswers = responseData.reduce((acc, item) => {
              acc[item.question_id] = item.answer?.split('|')[0] || '';
              return acc;
            }, {});
            setAnswers(newAnswers);
            const stepAnswersData = responseData.reduce((acc, item) => {
              acc[item.question_id] = item.answer?.split('|')[1] || '';
              return acc;
            }, {});
            setStepAnswer(stepAnswersData);
          } else {
            const newAnswers = responseData.reduce((acc, item) => {
              acc[item.question_id] = item.answer?.split('|')[0] || '';
              return acc;
            }, {});
            setAnswers(newAnswers);
          }
        });
      }
    } catch (error) {}
  };

  const fetchQuestions = async () => {
    try {
      setLoading(false);
      const response = await getAssessmentsQuestions(CONSTANTS.ASSESSMENT_QUESTIONS_IDS.ASSESSMENT_2, user?.id, user?.group);
      if (response?.data?.status === 200) {
        const translatedQuestions = response?.data?.data.map(question => ({
          ...question,
          question: translate(question.question) // Translate the question here
        }));
        setQuestions(translatedQuestions);
        // setQuestions(response?.data?.data);
        const initialAnswers = translatedQuestions.reduce((acc, curr) => {
          const questionId = curr?.id;
          if (questionId === 9) {
            const score = String(curr?.answer)?.split('|')[1] ? String(curr?.answer)?.split('|')[1] : 0;
            setStepAnswer(prev => {
              return {
                ...prev,
                [questionId]: score
              };
            });
          }
          if (questionId === 10) {
            const score = String(curr?.answer)?.split('|')[1] ? String(curr?.answer)?.split('|')[1] : 0;
            setStepAnswer(prev => {
              return {
                ...prev,
                [questionId]: score
              };
            });
          }
          if (questionId === 9 || questionId === 10) {
            acc[curr.id] = String(curr?.answer).split('|')[0] || '';
          } else {
            acc[curr.id] = curr?.answer || '';
          }
          return acc;
        }, {});
        setAnswers(initialAnswers);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleAnswerChange = (questionId, value) => {
    setError(null);
    if (questionId === 11) {
      setAnswers(prevAnswers => ({
        ...prevAnswers,
        [questionId]: prevAnswers[questionId] === value ? '' : value
      }));
    } else if (questionId === 9) {
      const selectedOption = doingAssessment.DOING_ASSESSMENT.find(option => option.value === value);
      const score = selectedOption ? selectedOption.score : 0;
      setStepAnswer(prev => {
        return {
          ...prev,
          [questionId]: score
        };
      });
      setAnswers(prevAnswers => ({
        ...prevAnswers,
        [questionId]: `${value}|${score}`
      }));
    }
    if (questionId === 10) {
      const selectedOption = beAssessment.BE_ASSESSMENT.find(option => option.value === value);
      const score = selectedOption ? selectedOption.score : 0;
      setStepAnswer(prev => {
        return {
          ...prev,
          [questionId]: score
        };
      });
      setAnswers(prevAnswers => ({
        ...prevAnswers,
        [questionId]: `${value}|${score}`
      }));
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const isAllQuestionsAnswered = Object.entries(answers).every(([questionId, answer]) => {
      if (questionId === '11') return true;
      return answer.trim() !== '';
    });
    if (isAllQuestionsAnswered) {
      await submitAnswers();
    } else {
      setError(CONSTANTS.MESSAGES.SOME_FIELDS_REQUIRED);
    }
  };

  const submitAnswers = async () => {
    const data = {
      user_id: user?.id,
      group_id: user?.group,
      questions: Object.entries(answers).map(([questionId, answer]) => ({
        question_id: questionId,
        answer: questionId == 9 || questionId == 10 ? `${answer.trim()}|${stepAnswer[questionId]}` : answer.trim()
      }))
    };
    try {
      setLoading(true);

      const response = await postAssessmentsQuestions(data);

      if (response?.data?.status === 200) {
        nextpage();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  return (
    <>
      <CssBaseline />
      <Box mt={1} px={3} overflow="auto">
        <FormWrapper
          buttonInputs={[
            {
              text: translate('Import Previous Year Assessment'),
              onClick: () => previousAnswer(),
              type: 'button',
              loading: loading,
              hide: level === 'D1'
            },
            {
              text: translate('Continue'),
              onClick: e => handleSubmit(e),
              type: 'submit'
            }
          ]}
          // headingText={`Assessment ${page} of ${totalPage}`}
          loading={loading}
        >
          <QuestionHeadings heading1={`${translate('ASSESSMENT')} ${page} ${translate('OF')} ${totalPage}`} heading2={translate('INITIAL PATHWAY ASSESSMENT')} heading3={translate('BY WEEK SIX')} />
          <Box pt={4} mb={1}>
            {questions.map((ques, index) => {
              if (ques?.id === 11) {
                return (
                  <Box key={index}>
                    <Typography variant="body2" textAlign="left">
                      {ques?.question}
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      {/* <CheckBoxInput onChange={() => handleAnswerChange(ques?.id, answersData[ques?.id][0])} checked={answers[ques?.id] === answersData[ques?.id][0]} label={answersData[ques?.id][0]} />
                      <CheckBoxInput onChange={() => handleAnswerChange(ques?.id, answersData[ques?.id][1])} checked={answers[ques?.id] === answersData[ques?.id][1]} label={answersData[ques?.id][1]} /> */}
                      {answersData[ques?.id].map((option, idx) => (
                        <CheckBoxInput key={idx} onChange={() => handleAnswerChange(ques?.id, option.value)} checked={answers[ques?.id] === option.value} label={option.label} name={option.value} />
                      ))}
                    </Box>
                  </Box>
                );
              } else if (ques?.id === 10) {
                return (
                  <Box key={index}>
                    <DropdownWithScore questionId={ques.id} label={ques.question} options={beAssessment.BE_ASSESSMENT} answers={answers} handleAnswerChange={handleAnswerChange} setStepAnswer={setStepAnswer} stepAnswer={stepAnswer} />
                  </Box>
                );
              } else {
                return (
                  <Box key={index}>
                    <DropdownWithScore questionId={ques.id} label={ques.question} options={doingAssessment.DOING_ASSESSMENT} answers={answers} handleAnswerChange={handleAnswerChange} setStepAnswer={setStepAnswer} stepAnswer={stepAnswer} />
                  </Box>
                );
              }
            })}
          </Box>
          <Grid item xs={12} mt={2} sx={{height: '50px'}}>
            {error && <Alert severity="error">{error}</Alert>}
          </Grid>
        </FormWrapper>
      </Box>
    </>
  );
};

export default PageThree;
